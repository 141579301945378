import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubjectApprovedComponent } from './subject-approved/subject-approved.component';
import { SubjectCertifiedComponent } from './subject-certified/subject-certified.component';
import { SubjectPendingComponent } from './subject-pending/subject-pending.component';
import { SubjectStudyingComponent } from './subject-studying/subject-studying.component';
import { SubjectUnapprovedComponent } from './subject-unapproved/subject-unapproved.component';
import { CelicApprovalListFormalComponent } from './celic-approval-list-formal.component';
import { CommonApprovalModule } from '../common/common-approval.module';


@NgModule({
  declarations: [
    SubjectApprovedComponent,
    SubjectCertifiedComponent,
    SubjectPendingComponent,
    SubjectStudyingComponent,
    SubjectUnapprovedComponent,
    CelicApprovalListFormalComponent
  ],
  imports: [
    CommonModule,
    CommonApprovalModule
  ],
  exports:[
    CelicApprovalListFormalComponent
  ]
})
export class CelicApprovalListFormalModule { }

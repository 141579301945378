<div class="approval-container">
  <!-- If the schools is Celic and I have children -->
  <ng-container *ngIf="isMyApproval && this.SCHOOL_NAME === 'CELIC'">
    <div class="select-child__container" *allowedRoles="[29]">
      <label for="">Seleccionar hijos ({{ childrenList.length }})</label>
      <div class="select-child">
        <select name="" id="" (change)="switchUser($event)">
          <option [value]="" disabled selected>Seleccionar hijo</option>
          <option [value]="child.id" *ngFor="let child of childrenList">
            {{ child.Name1 | titlecase }} {{ child.Name2 | titlecase }}
            {{ child.LastName1 | titlecase }} {{ child.LastName2 | titlecase }}
          </option>
        </select>
        <em class="icon-i-angle-right select-icon"></em>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="targetUser">
    <approval-header [userInfo]="targetUser"> </approval-header>

    <div class="approval">
      <h2 class="approval-text approval-text--lg approval-text--bold approval-text--gray">
        TODOS LOS PROGRAMAS
      </h2>

      <div class="approval__program-container">
        <div class="program-container">
          <div class="program-container__header">
            <span class="approval-text approval-text--gray">Programa</span>
            <span class="approval-text approval-text--center approval-text--gray">Fecha inicial</span>
            <span class="approval-text approval-text--center approval-text--gray">Estado</span>
          </div>

          <div class="program-container__body">
            <approval-card (click)="onSelectCourse($event, course)"
              *ngFor="let course of userCourses"
              [courseInfo]="course.coursesOfers"
              [isWithdrawn]="course.isWithdrawn"
            >
            </approval-card>
          </div>
        </div>

        <div class="approval__subject-container">
          <div class="subject-container__title" *ngIf="!showSubjects">
            <h2 class="approval-text approval-text--xl approval-text--bold approval-text--center">
              SELECCIONE UN PROGRAMA PARA VER DETALLES
            </h2>
          </div>

          <div class="approval__subject-pills" *ngIf="showSubjects">
            <button class="button button--notes" (click)="showNotesModal = true">
              Ver todas las notas
            </button>
            <h2 class="approval-text approval-text--lg approval-text--gray">
              MATERIAS ORDENADAS POR
            </h2>

            <div class="subject__pills-container">
              <div class="subject__pills-inner">
                <div class="subject__pill subject__pill--all"
                  (click)="filter = 'all'"
                  [ngClass]="filter === 'all' ? 'subject__pill--active-all subject__pill--active' : ''"
                >
                  <p class="approval-text approval-text--md">Todas</p>
                </div>

                <div class="subject__pill subject__pill--studying"
                  (click)="filter = 'studying'"
                  [ngClass]="filter === 'studying' ? 'subject__pill--active--studying subject__pill--active' : ''"
                >
                  <p class="approval-text approval-text--md">Cursando</p>
                </div>

                <div class="subject__pill subject__pill--approved"
                  (click)="filter = 'approved'"
                  [ngClass]="filter === 'approved' ? 'subject__pill--active-approved subject__pill--active' : ''"
                >
                  <p class="approval-text approval-text--md">Aprobadas</p>
                </div>

                <div class="subject__pill subject__pill--pending"
                  (click)="filter = 'pending'"
                  [ngClass]="filter === 'pending' ? 'subject__pill--active-pending subject__pill--active' : ''"
                >
                  <p class="approval-text approval-text--md">Pendientes</p>
                </div>

                <div class="subject__pill subject__pill--unapproved"
                  (click)="filter = 'unapproved'"
                  [ngClass]="filter === 'unapproved' ? 'subject__pill--active-unapproved subject__pill--active': ''"
                >
                  <p class="approval-text approval-text--md">Perdidas</p>
                </div>

                <div class="subject__pill subject__pill--certified"
                  (click)="filter = 'certified'"
                  [ngClass]="filter === 'certified' ? 'subject__pill--active-certified subject__pill--active' : ''"
                >
                  <p class="approval-text approval-text--md">Homologadas</p>
                </div>
              </div>
            </div>

            <celic-approval-list-formal [selectedSubject]="filter"
              [selectedCourseInfo]="selectedCourseInfo"
              [userInfo]="targetUser"
              [isWithinDateRange]="isWithinDateRange"
              *ngIf="SCHOOL_NAME === 'CELIC'"
            >
            </celic-approval-list-formal>
            <!-- && selectedCourseInfo.coursesOfers.TypeCourseOFer?.Category?.gradesComponentName === 'formalEduComponent' -->

  <!--           <approval-list-formal [selectedSubject]="filter"
              [selectedCourseInfo]="selectedCourseInfo"
              [userInfo]="targetUser"
              [isWithinDateRange]="isWithinDateRange"
              *ngIf="SCHOOL_NAME !== 'CELIC' && selectedCourseInfo.coursesOfers.TypeCourseOFer?.Category?.gradesComponentName === 'formalEduComponent'"
            >
            </approval-list-formal> -->

<!--             <approval-list-military [selectedSubject]="filter"
              [selectedCourseInfo]="selectedCourseInfo"
              [userInfo]="targetUser"
              [isWithinDateRange]="isWithinDateRange"
              *ngIf="selectedCourseInfo.coursesOfers.TypeCourseOFer?.Category?.gradesComponentName === 'millEduComponent'"
            >
            </approval-list-military>

            <approval-list-superior [selectedSubject]="filter"
              [selectedCourseInfo]="selectedCourseInfo"
              [userInfo]="targetUser"
              [isWithinDateRange]="isWithinDateRange"
              *ngIf="selectedCourseInfo.coursesOfers.TypeCourseOFer?.Category?.gradesComponentName === 'supEduComponent'"
            >
            </approval-list-superior> -->

            <notes-modal [isWithinDateRange]="isWithinDateRange"
              [selectedCourseInfo]="selectedCourseInfo"
              (onCloseModal)="showNotesModal = false"
              *ngIf="showNotesModal"
            >
            </notes-modal>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

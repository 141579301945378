import { Injectable, Type } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { School } from '../models/school';
import { RoleMapping } from '../models/roleMapping';
import { CourseOffer } from '../models/courseOffer';
import { CourseGroup } from '../models/courseGroup';
import { AcademicArea } from '../models/academicArea';
import { Subject } from '../models/subject';
import { SubjectGroup } from '../models/subjectGroup';
import { UserJobContract } from '../models/userJobContract';
import { User } from '../models/user';
import { DateRecord } from '../models/dateRecord';
import { DateMidTerm } from '../models/dateMidTerm';
import { ScheduleSubject } from '../models/scheduleSubject';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(
    private _httpService: HttpService
  ) { }

  getMyRoleMappings(filter?: string): Observable<RoleMapping[]> {
    let url: string = `/Userapps/me/roleMappings`
    if (filter)
      url = `/Userapps/me/roleMappings?filter=${filter}`
    return this._httpService.httpGet(url);
  }


  getSchoolInfo(filter?: string): Observable<School[]> {
    let url: string = `/Schools`
    if (filter)
      url = `/Schools?filter=${filter}`
    return this._httpService.httpGet(url);
  }
  getSchoolInfoByID(SchoolID: number, filter?: string): Observable<School> {
    let url: string = `/Schools/${SchoolID}`
    if (filter)
      url = `/Schools/${SchoolID}?filter=${filter}`
    return this._httpService.httpGet(url);
  }

  getCourseOfers(filter?: string): Observable<CourseOffer[]> {
    let url: string = `/CoursesOfers`
    if (filter)
      url = `/CoursesOfers/?filter=${filter}`
    return this._httpService.httpGet(url);
  }

  getCourseGroups(filter?: string): Observable<CourseGroup[]> {
    let url: string = `/CourseGroups`
    if (filter)
      url = `/CourseGroups?filter=${filter}`
    return this._httpService.httpGet(url);
  }
  getAcademicAreasBySchoolID(SchoolID: number, filter?: string): Observable<AcademicArea[]> {
    let url: string = `/Schools/${SchoolID}/AcademicAreas`
    if (filter)
      url = `/Schools/${SchoolID}/AcademicAreas?filter=${filter}`
    return this._httpService.httpGet(url);
  }
  getSubjects(filter?: string): Observable<Subject[]> {
    let url: string = `/subjects`
    if (filter)
      url = `/subjects?filter=${filter}`
    return this._httpService.httpGet(url);
  }
  getSubjectsV2Offer(filter?: string): Observable<Subject[]> {
    let url: string = `/SubjectV2Offers`
    if (filter)
      url = `/SubjectV2Offers?filter=${filter}`
    return this._httpService.httpGet(url);
  }
  getSubjectGroups(filter?: string): Observable<SubjectGroup[]> {
    let url: string = `/SubjectGroups`
    if (filter)
      url = `/SubjectGroups?filter=${filter}`
    return this._httpService.httpGet(url);
  }
  getUserJobsContract(filter?: string): Observable<UserJobContract[]> {
    let url: string = `/UserJobsContracts`
    if (filter)
      url = `/UserJobsContracts?filter=${filter}`
    return this._httpService.httpGet(url);
  }
  getUserByID(UserID: number, filter?: string): Observable<User> {
    let url: string = `/Userapps/${UserID}`
    if (filter)
      url = `/Userapps/${UserID}?filter=${filter}`
    return this._httpService.httpGet(url);
  }
  patchSubjectGroup(data): Observable<SubjectGroup> {
    let url: string = `/SubjectGroups`

      return this._httpService.httpPatch(url, data);


  }
  patchDateRecord(data): Observable<SubjectGroup> {
    let url: string = `/DateRecords`
    return this._httpService.httpPatch(url, data);
  }
  assignUserSubject(data): Observable<any> {
    let url: string = `/UserSubjects/assignUserSubject`
    return this._httpService.httpPost(url, data);
  }

  getDateRecords(filter?: string): Observable<DateRecord[]> {
    let url: string = `/DateRecords`
    if (filter)
      url = `/DateRecords?filter=${filter}`
    return this._httpService.httpGet(url);
  }
  getDateMidTerms(filter?: string): Observable<DateMidTerm[]> {
    let url: string = `/DateMidTerms`
    if (filter)
      url = `/DateMidTerms?filter=${filter}`
    return this._httpService.httpGet(url);
  }
  getMidTermsBySubjectGroupID(SubjectGroupID:number, filter?: string): Observable<any[]> {
    let url: string = `/SubjectGroups/${SubjectGroupID}/minTerms`
    if (filter)
      url = `/SubjectGroups/${SubjectGroupID}/minTerms?filter=${filter}`
    return this._httpService.httpGet(url);
  }

  patchScheduleSubjects(data): Observable<ScheduleSubject> {
    let url: string = `/ScheduleSubjects`
    return this._httpService.httpPatch(url, data);
  }
  deleteScheduleSubjectById(shcheduleSubjectID:number): Observable<any> {
    let url: string = `/ScheduleSubjects/${shcheduleSubjectID}`
    return this._httpService.httpDelete(url);
  }

  


}



import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubjectApprovedComponent } from './subject-approved/subject-approved.component';
import { SubjectCertifiedComponent } from './subject-certified/subject-certified.component';
import { SubjectPendingComponent } from './subject-pending/subject-pending.component';
import { SubjectStudyingComponent } from './subject-studying/subject-studying.component';
import { SubjectUnapprovedComponent } from './subject-unapproved/subject-unapproved.component';
import { ApprovalListMilitaryComponent } from './approval-list-military.component';
import { CommonApprovalModule } from '../common/common-approval.module';

@NgModule({
  declarations: [
    SubjectApprovedComponent,
    SubjectCertifiedComponent,
    SubjectPendingComponent,
    SubjectStudyingComponent,
    SubjectUnapprovedComponent,
    ApprovalListMilitaryComponent
  ],
  imports: [
    CommonModule,
    CommonApprovalModule
  ],
  exports:[
    ApprovalListMilitaryComponent
  ]
})
export class ApprovalListMilitaryModule { }

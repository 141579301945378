<div class="observations-section">
  <!-- If the schools is Celic and I have children -->
  <div class="select-child__container"
    *ngIf="isMyObservation && allowedRoles([29])"
  >
    <label for="">Seleccionar hijos ({{ childrenList.length }})</label>
    <div class="select-child">
      <select name="" id="" (change)="switchUser($event)">
        <option [value]="" disabled selected>Seleccionar hijo</option>
        <option [value]="child.id" *ngFor="let child of childrenList">
          {{ child.Name1 | titlecase }} {{ child.Name2 | titlecase }}
          {{ child.LastName1 | titlecase }} {{ child.LastName2 | titlecase }}
        </option>
      </select>
      <em class="icon-i-angle-right select-icon"></em>
    </div>
  </div>

  <!-- Observations -->
  <div class="observations" *ngIf="targetUser">
    <div class="left-panel">
      <div class="profile">
        <ng-container *ngTemplateOutlet="
            defaultAvatar;
            context: {
              photoClass: 'profile-img--lg',
              avatarClass: 'default-avatar--lg',
              imageUrl: targetUser.Photo
            }"
        >
        </ng-container>

        <div class="profile-info">
          <h2 class="profile-name">
            {{ targetUser.Name1 | titlecase }}
            {{ targetUser.Name2 | titlecase }}
            {{ targetUser.LastName1 | titlecase }}
            {{ targetUser.LastName2 | titlecase }}
          </h2>
          <span class="profile-school" *ngIf="schoolList.length > 0">{{
            schoolList[0].schoolName
          }}</span>
          <span class="profile-grade">{{ userGrade || "Sin grado" }}</span>
        </div>
      </div>

      <a type="button"
        class="button button--blue button--full"
        [routerLink]="['/Cv-Public', targetUser.CedocEmail]"
        target="_blank">Ver hoja de vida</a>

      <div class="left-panel__user-info">
        <div class="user-info__container">
          <h2 class="text text--blue text--bold">Condiciones Médicas</h2>
          <div class="user-info">
            <p class="text text--gray">
              {{targetUser.ContactInfos?.[0]?.medicalCondition || '...' }}
            </p>
          </div>
        </div>

        <div class="user-info__container">
          <h2 class="text text--blue text--bold">Notas</h2>
          <div class="user-info">
            <p class="text text--gray">
              {{targetUser.ContactInfos?.[0]?.notes || '...' }}
            </p>
          </div>
        </div>

        <div class="user-info__container">
          <h2 class="text text--blue text--bold">Contacto</h2>
          <ul class="user-info user-info--spacing">
            <li class="text text--gray">
              <span class="text--blue">Teléfono: </span>
              {{targetUser.ContactInfos?.[0]?.CellPhone || '...' }}
            </li>
            <li class="text text--gray">
              <span class="text--blue">Correo contacto: </span>
              {{targetUser.ContactInfos?.[0]?.Email || '...' }}
            </li>
            <li class="text text--gray">
              <span class="text--blue">Correo institucional: </span>
              {{ targetUser.CedocEmail }}
            </li>
            <li class="text text--gray"><span class="text--blue"></span></li>
          </ul>
        </div>

        <div class="user-info__container" *ngIf="parentList.length > 0">
          <h2 class="text text--blue text--bold">Responsables</h2>

          <div class="user-info user-info--spacing">
            <ul *ngFor="let item of parentList">
              <li class="text text--gray">
                <span class="text--blue">Responsable: </span>
                {{ item.parentUser.Name1 | titlecase }}
                {{ item.parentUser.Name2 | titlecase }}
                {{ item.parentUser.LastName1 | titlecase }}
                {{ item.parentUser.LastName2 | titlecase }}
              </li>
              <li class="text text--gray">
                <span class="text--blue">Teléfono: </span>
                {{ item.parentUser.ContactInfos?.[0]?.CellPhone }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="right-panel">
      <div class="observations-header">
        <h1 class="main-title">Observaciones</h1>

        <div class="observations-header__actions">
          <a class="button button--blue button--icon"
            href="https://drive.google.com/uc?export=download&id=15BXkXmuaBlR4kPfX-UZuPozJip_98iqk"
            target="_blank"
            rel="noopener"
          >
            <em class="icon-i-download comment-download__icon"></em>
            Manual de convivencia
          </a>
          <button type="button"
            class="button button--blue button--icon"
            (click)="toggleModal()"
            *ngIf="!isMyObservation && allowedRoles([1, 3, 18, 31, 32, 37, 38, 40])"
          >
            <em class="icon-i-plus"></em>
            Nueva observación
          </button>
          <button type="button"
            class="button button--blue button--icon"
            (click)="print()"
            *ngIf="!isMyObservation && allowedRoles([1, 3, 18, 31, 32, 37, 38, 40])"
          >
            <em class="icon-i-plus"></em>
            Imprimir
          </button>
        </div>
      </div>

      <div class="comment-filters">
        <div class="comment__date-filter">
          <select (change)="filterByYear($event)">
            <option value="" disabled selected hidden>Filtrar por año</option>
            <option [value]="0">Todos los años</option>
            <option [value]="year" *ngFor="let year of yearList">
              Año {{ year }}
            </option>
          </select>
          <em class="icon-i-angle-right select-icon"></em>
        </div>
      </div>

      <ng-container *ngFor="let item of observations">
        <div class="comment-card" *ngIf="!item.observationID">
          <div class="comment-profile">
            <div class="profile-header">
              <ng-container
                *ngTemplateOutlet="defaultAvatar;
                  context: {
                    photoClass: 'profile-img--md',
                    avatarClass: 'default-avatar default-avatar--md',
                    imageUrl: item.observer.Photo
                  }"
              >
              </ng-container>

              <div>
                <p class="text text--black">
                  {{ item.observer.Name1 | titlecase }}
                  {{ item.observer.Name2 | titlecase }}
                  {{ item.observer.LastName1 | titlecase }}
                  {{ item.observer.LastName2 | titlecase }}
                </p>
                <p class="text text--sm text--black">
                  {{ item.observer.CedocEmail || item.observer.email }}
                </p>
              </div>
            </div>

            <div class="comment-actions">
              <span class="text text--gray"> ID: {{ item.id }} </span>
              <span class="text text--gray">{{
                item.date | date : "dd/MM/yyyy hh:mm" : "UTC"
              }}</span>
              <button class="button button--red"
                type="button"
                (click)="deleteObservations(item.id)"
                *ngIf="allowedRoles([1])"
              >
                Eliminar
              </button>
            </div>
          </div>

          <div class="categories" *ngIf="item.typeObservationID">
            <h2 class="text text--black">
              Categoría:
              <span class="text">{{
                observationTypeNames[item.typeObservationID].name
              }}</span>
            </h2>
            <div class="subcategory-list">
              <ng-container *ngFor="let subtype of item.subTypeObservationIDs">
                <span class="subcategory-item" *ngIf="observationSubTypeNames[item.typeObservationID][subtype]">
                  <img [src]="observationSubTypeNames[item.typeObservationID][subtype].image"
                    class="subcategory-item__icon" alt="school icon"
                    *ngIf="observationSubTypeNames[item.typeObservationID][subtype].image"
                  />
                  {{ observationSubTypeNames[item.typeObservationID][subtype].name }}
                </span>
              </ng-container>
            </div>
          </div>

          <div class="observation-info">
            <h2 class="text text--black">
              Oferta: <span class="text--blue"></span>
            </h2>
            <p class="text text--gray">{{ item.courseOffer?.NameCourseOfer || 'Sin oferta' }}</p>
          </div>

          <div class="observation-info">
            <h2 class="text text--black">
              Observación: <span class="text--blue"></span>
            </h2>
            <p class="text text--gray">{{ item.comment }}</p>
          </div>
          

          <div class="observation-info">
            <div>
              <h2 class="text text--black">Anotación final</h2>
              <div class="final-comment__container">
                <div *ngIf="item.finalComment || item.minuteAttachment">
                  <p class="text text--gray text--italic" *ngIf="item.finalComment">
                    {{ item.finalComment }}
                  </p>
                  <a class="final-comment__link"
                    *ngIf="item.minuteAttachment"
                    [href]="baseUrl + item.minuteAttachment"
                    target="_blank"
                  >
                    <em class="icon-i-download"></em>
                    Descargar adjunto
                  </a>
                </div>

                <ng-container *ngIf="!(item.finalComment || item.minuteAttachment) &&
                    item.observerID === this.observerUser!.id &&
                    allowedRoles([1, 3, 18, 31, 32, 37, 38, 40])
                  "
                >
                  <div class="comment-input">
                    <input type="text"
                      placeholder="Escriba su anotación final"
                      #additionalComment
                    />
                  </div>

                  <div class="upload-input">
                    <upload-file [formats]="['.jpg', '.jpeg', '.png', '.pdf']"
                      (onSaveFiles)="storaFinalComment(additionalComment, $event, item)"
                    ></upload-file>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="subcomments">
            <h2 class="text text--black">
              Comentarios ({{ item.additionalComments.length }})
            </h2>
            <span class="prevent-comment" *ngIf="allowedRoles([2, 29])">
              Sólo se permite un comentario por observación.
            </span>

            <div class="subcomment-list">
              <div class="subcomment"
                *ngFor="let additional of item.additionalComments"
              >
                <div class="subcomment-profile">
                  <ng-container *ngTemplateOutlet="
                      defaultAvatar;
                      context: {
                        photoClass: 'profile-img--sm',
                        avatarClass: 'default-avatar default-avatar--sm',
                        imageUrl: additional.observer.Photo
                      }"
                  >
                  </ng-container>

                  <div>
                    <p class="text text--sm text--black">
                      <span class="text--bold">
                        {{ additional.observer.Name1 | titlecase }}
                        {{ additional.observer.Name2 | titlecase }}
                        {{ additional.observer.LastName1 | titlecase }}
                        {{ additional.observer.LastName2 | titlecase }}
                      </span>
                      <span class="subcomment-profile__date">
                        {{
                          additional.date | date : "dd/MM/yyyy hh:mm" : "UTC"
                        }}
                      </span>
                    </p>
                    <p class="text text--sm text--black">
                      {{ additional.comment }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="comment-input__container"
                *ngIf="!shouldPreventCommenting(item)"
              >
                <h2 class="text text--sm text--black text--bold">Comentar</h2>

                <div class="comment-input">
                  <input type="text"
                    placeholder="Escriba su comentario"
                    #additionalComment
                  />
                  <em class="icon-i-send comment-icon"
                    (click)="storeAdditionalComment(additionalComment, item)"
                  ></em>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <p class="text text--blue" *ngIf="observations.length === 0">
        Sin observaciones
      </p>
    </div>
  </div>

  <!-- Create observation modal -->
  <div class="observation-modal"
    *ngIf="showObservationModal"
    (mousedown)="toggleModal()"
  >
    <div class="observation-modal__content"
      (mousedown)="stopPropagation($event)"
    >
      <h2 class="observation-modal__title">Nueva observación</h2>

      <form [formGroup]="observationForm"
        (submit)="storeObservation()"
        class="observation-modal__form"
      >
        <div class="observation-modal__input-container">
          <label for="" class="observation-modal__label">
            Tipo de observación
          </label>
          <select class="observation-modal__input"
            [formControlName]="'typeObservationID'"
            (change)="getObservationSubtypes()"
          >
            <option [value]="''" selected disabled>Seleccionar</option>
            <option [ngValue]="item.id" *ngFor="let item of typeObservations">
              {{ item.name }}
            </option>
          </select>
          <small class="observation-error"
            *ngIf="observationForm.get('typeObservationID')?.errors?.['required'] && validateErrors"
            >Este campos es obligatorio</small
          >
        </div>

        <custom-search-select [itemList]="subTypeObservations"
          [title]="'Subtipos'"
          [extraValidation]="observationForm.get('subTypeObservationIDs')?.errors?.['required'] && validateErrors"
          (addedItems)="getSubTypeObservationIDs($event)"
        >
        </custom-search-select>

        <div class="observation-modal__input-container">
          <label for="" class="observation-modal__label">Comentario</label>
          <textarea rows="4"
            class="observation-modal__input"
            style="resize: none"
            [formControlName]="'comment'"
            placeholder="Escriba aquí"
          ></textarea>
          <small class="observation-error" *ngIf="observationForm.get('comment')?.errors?.['required'] && validateErrors">
            Este campos es obligatorio
          </small>
        </div>

        <div class="observation-modal__actions">
          <button type="submit" class="button button--blue button--icon">
            <em class="icon-i-plus"></em>
            Agregar observación
          </button>

          <button class="button button--cancel button--icon"
            type="button"
            (click)="toggleModal()"
          >
            <em class="icon-i-close"></em>
            Cancelar
          </button>
        </div>
      </form>
    </div>
  </div>

  <ng-template #defaultAvatar
    let-photoClass="photoClass"
    let-avatarClass="avatarClass"
    let-imageUrl="imageUrl"
  >
    <img [src]="imageUrl"
      alt="Profile picture"
      class="profile-img"
      [ngClass]="photoClass"
      *ngIf="imageUrl"
    />
    <em class="icon-i-user-login default-avatar"
      [ngClass]="avatarClass"
      *ngIf="!imageUrl"
    ></em>
  </ng-template>
</div>

<div class="print-observations" *ngIf="targetUser && observations">
  <print-observations
    [observations]="observations"
    [schoolList]="schoolList"
    [targetUser]="targetUser"
    [observationTypeNames]="observationTypeNames"
    [observationSubTypeNames]="observationSubTypeNames">
  </print-observations>
</div>
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { APP_BASE_HREF } from '@angular/common';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { FullScheduleComponent } from './components/full-schedule/full-schedule.component';
import { StudentCalendarComponent } from './components/student-calendar/student-calendar.component';
import { ProfessorCalendarComponent } from './components/professor-calendar/professor-calendar.component';
import { AcademicCalendarComponent } from './components/academic-calendar/academic-calendar.component';
import { FullScheduleV2Component } from './components/full-schedule-v2/full-schedule-v2.component';


const routes: Routes = [
  { path: 'schedule', component: ScheduleComponent },
  { path: 'schedule/full', component: FullScheduleComponent },
  { path: 'schedule/fullV2', component: FullScheduleV2Component },
  { path: 'schedule/student', component: StudentCalendarComponent },
  { path: 'schedule/professor', component: ProfessorCalendarComponent },
  { path: 'schedule/academic/:courseOferID', component: AcademicCalendarComponent },
  { path: '**', component: EmptyRouteComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
  ],
})
export class AppRoutingModule { }

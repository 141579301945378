import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component( {
  selector: 'subject-pending',
  templateUrl: './subject-pending.component.html',
  styleUrls: [ './subject-pending.component.css' ]
} )
export class SubjectPendingComponent implements OnInit {

  constructor () {
    //
  }

  @Output( 'subjectActions' ) subjectActions: EventEmitter<any> = new EventEmitter();
  @Output( 'deleteAction' ) deleteAction: EventEmitter<any> = new EventEmitter();

  @Input( 'subjectGroup' ) subjectGroup: any = {};
  @Input( 'showStatus' ) showStatus: boolean = false;

  //allows to display or hide the additional information of the subject
  public showAdditionalData: boolean = false;

  ngOnInit(): void {
    //
  }

  /**
   * Returns an object with styles for a CSS Grid layout.
   * @returns
   */
  public setColumns() {
    return {
      display: 'grid',
      'grid-template-columns': `3fr repeat(${ this.showStatus ? '4' : '3' }, 1fr)`,
      'align-items': 'center',
    }
  }
}

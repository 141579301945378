<section class="register-container">
  <ng-container *ngIf="showStudentList">
    <div class="table-container">
      <h2 class="title">Lista de aspirantes / estudiantes</h2>

      <div class="table-container__content">
        <ng-container *ngIf="children.length === 0 && isLoading">
          <h2 class="table-container__content-empty">
            Cargando datos ...
          </h2>
        </ng-container>

        <table class="table" *ngIf="children.length > 0">
          <thead>
            <tr>
              <th class="table-header">Nombre</th>
              <th class="table-header">Parentesco</th>
              <th class="table-header">Tipo de documento</th>
              <th class="table-header">Número de documento</th>
              <th class="table-header">Correo electrónico</th>
              <th class="table-header">Acudiente</th>
              <!-- <th class="table-header">Transferir</th> -->
              <th class="table-header"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let child of children; let i = index">
              <td class="table-data">
                <div class="table-data__content">
                  <img src="{{ child.studentUser.Photo }}" alt="profile image" class="profile-image" *ngIf="child.studentUser.Photo">
                  <em class="icon-i-user-login default-avatar" *ngIf="!child.studentUser.Photo"></em>

                  <div class="table-profile__container">
                    <h2 class="profile-container__title">
                      {{ child.studentUser.Name1 | titlecase }}
                      {{ child.studentUser.LastName1 | titlecase }}
                    </h2>

                    <ng-container *ngIf="child.studentUser.UserDocuments.length > 0 && child.studentUser.ContactInfos.length > 0; else invalidChild">
                      <a [routerLink]="['/Cv-Public',child.studentUser.CedocEmail]" target="_blank"
                        class="profile-container__item">
                        <em class="icon-i-view-details profile-container__icon"></em>
                        Ver hoja de vida
                      </a>
                    </ng-container>
                    <ng-template #invalidChild>
                      <p class="invalid-child">Datos incompletos: documentos o contacto.</p>
                    </ng-template>
                  </div>
                </div>
              </td>

              <td class="table-data">
                <div class="table-data__content">
                  <div class="table-data__content-attendant">
                    <div class="select-container ">
                      <select class="select" [ngClass]="{'disabled': assigningMainRelationship}" (change)="setMainRelationship($event, child.id)">
                        <option selected disabled>Seleccionar</option>
                        <option [value]="item.id" [selected]="item.id === child.typeRlelationshipParent?.id" 
                          *ngFor="let item of relationships">
                          {{item.nameRelationship}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </td>
              <td class="table-data">
                <div class="table-data__content">{{getTypeDocumentName(child.studentUser.UserDocuments?.[0]?.TypeDocumentID)}}</div>
              </td>
              <td class="table-data">
                <div class="table-data__content">{{ child.studentUser.UserDocuments?.[0]?.Document || 'Datos incompletos' }}</div>
              </td>
              <td class="table-data">
                <div class="table-data__content">{{ child.studentUser.CedocEmail }}</div>
              </td>
              <td class="table-data">
                <div class="table-data__content">
                  <div class="table-data__content-attendant" *ngIf="child.attendantUser">
                    <span>
                      {{ child.attendantUser.Name1 | titlecase }}
                      {{ child.attendantUser.LastName1 | titlecase }} 
                      - {{ child.attendantUser.UserDocuments?.[0]?.Document }}
                    </span>
                    <span *ngIf="child.typeRlelationshipAttended">{{ child.typeRlelationshipAttended.nameRelationship }}</span>
                  </div>
                  <button class="button button--attendant" (click)="setDropDownPosition($event); onSetRelationship(child.id)">Asignar acudiente</button>
                </div>
              </td>
              <!-- <td class="table-data">
                <div class="table-data__transfer">
                  <button class="button"
                    (click)="getChildToTransfer(child)">
                    <em class="icon-i-refresh transfer-icon" [ngClass]="{
                      'transfer-icon--rejected':child.isAccepted === false,
                      'transfer-icon--process': child.isAccepted === null &&
                        (child.TransferParentUserId !== null &&
                        child.TransferParentUserId !== currentUser.id)
                    }">
                    </em>
                  </button>
                </div>
              </td> -->
              <td class="table-data">
                <button class="btn btn-primary" (click)="changeUser(child)">Cambiar perfil</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="table-container__actions">
        <button class="button button--blue" [ngClass]="isLoading ? 'button--disabled' : ''"
          (click)="openRegisterForm()">Registrar Estudiante</button>
        <!-- <button class="button button--blue button--icon" [ngClass]="isLoading ? 'button--disabled' : ''"
          (click)="showChildrenModal = true">
          <span class="dot-icon" *ngIf="childrenToAccept.length > 0"></span>
          {{childrenToAccept.length}} Solicitud De Transferencia
        </button> -->
      </div>
    </div>

    <!-- RESPONSIBLE USER -->
    <ng-container *ngIf="responsibleUser">
      <div class="table-container table-container--responsibleUser">
        <h2 class="title">Acudiente de: </h2>
        <div class="table-container__content">
          <table class="table" *ngIf="children.length > 0">
            <thead>
              <tr>
                <th class="table-header">Nombre</th>
                <th class="table-header">Documento</th>
                <th class="table-header">Responsable</th>
                <th class="table-header">Documento del responsable</th>
                <th class="table-header">Parentesco</th>
                <th class="table-header"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="table-data">
                  <div class="table-data__content">{{responsibleUser.studentUser.Name1}} {{responsibleUser.studentUser.LastName1}}</div>
                </td>
                <td class="table-data">
                  <div class="table-data__content">{{responsibleUser.studentUser.UserDocuments?.[0]?.Document || 'Datos incompletos'}}</div>
                </td>
                <td class="table-data">
                  <div class="table-data__content">{{responsibleUser.parentUser.Name1}} {{responsibleUser.parentUser.LastName1}}</div>
                </td>
                <td class="table-data">
                  <div class="table-data__content">{{responsibleUser.parentUser.UserDocuments?.[0]?.Document || 'Datos incompletos'}}</div>
                </td>
                <td class="table-data">
                  <div class="table-data__content">{{responsibleUser.typeRlelationshipAttended?.nameRelationship || '-'}}</div>
                </td>
                <td class="table-data">
                  <ng-container *ngIf="responsibleUser.parentUser.UserDocuments?.[0]?.Document"></ng-container>
                  <button class="btn btn-primary" (click)="changeUser(responsibleUser)">Cambiar perfil</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>

    <!--TRANSFER MODAL -->
    <!-- <div class="transfer-modal__container" [ngStyle]="{'visibility': showTransferModal ? 'visible' : 'hidden'}">
      <div class="transfer-modal">
        <div class="transfer-modal__header-container">
          <div class="transfer-modal__header">
            <h2 class="transfer-modal__title">Transferir estudiante</h2>
            <button (click)="closeParenTransformModal()" class="button"><em
                class="icon-i-close close-icon"></em></button>
          </div>

          <div class="transfer-modal__header-content">
            <div class="warning-box"
              *ngIf="childToTransfer && childToTransfer.isAccepted !== null && childToTransfer.isAccepted === false">
              <h3 class="warning-box__header">Usuario rechazado</h3>
              <div class="warning-box__content">
                <p>Este usuario ha sido rechazado previamente por
                  {{childToTransfer.transferParentUser.Name1 | titlecase}}
                  {{childToTransfer.transferParentUser.Name2 | titlecase}}
                  {{childToTransfer.transferParentUser.LastName1 | titlecase}}
                  {{childToTransfer.transferParentUser.LastName2 | titlecase}} - Documento
                  {{childToTransfer.transferParentUser.UserDocuments[0].Document}}</p>
              </div>
            </div>

            <div class="info-box"
              *ngIf="childToTransfer && childToTransfer.isAccepted === null && childToTransfer.transferParentUser !== null">
              <h3 class="info-box__header">Usuario en proceso</h3>
              <div class="info-box__content">
                <p>Este usuario está pendiente de ser aceptado por
                  {{childToTransfer.transferParentUser.Name1 | titlecase}}
                  {{childToTransfer.transferParentUser.Name2 | titlecase}}
                  {{childToTransfer.transferParentUser.LastName1 | titlecase}}
                  {{childToTransfer.transferParentUser.LastName2 | titlecase}} - Documento
                  {{childToTransfer.transferParentUser.UserDocuments[0].Document}}</p>
              </div>
            </div>

            <p *ngIf="childToTransfer">
              Esta a punto de transferir a
              {{childToTransfer.studentUser.Name1 | titlecase}}
              {{childToTransfer.studentUser.Name2 | titlecase}}
              {{childToTransfer.studentUser.LastName1 | titlecase}}
              {{childToTransfer.studentUser.LastName2 | titlecase}}
            </p>
          </div>
        </div>

        <div>
          <label class="transfer-modal__label">Escriba el documento de la persona</label>
          <input class="transfer-modal__input" [ngClass]="isLoading ? 'disabled' : ''" type="text"
            placeholder="Escriba aquí" id="parent-input" autocomplete="off" #newParentInput>
        </div>

        <ul class="parent-list" *ngIf="parentList.length > 0 && showParentList">
          <li class="parent-list__item" *ngFor="let item of parentList">
            <button class="button" (click)="getParenToTransfer(item)">
              <span class="parent-list__item-name">{{item.Name1 | titlecase}} {{item.Name2 | titlecase}}
                {{item.LastName1
                | titlecase}} {{item.LastName2 | titlecase}}</span>
              <span class="parent-list__item-document">Documento: {{item.Document}}</span>
            </button>
          </li>
        </ul>

        <div class="selected-parent" *ngIf="selectedParent">
          El responsable del estudiante será:
          {{selectedParent.Name1 | titlecase}}
          {{selectedParent.Name2 | titlecase}}
          {{selectedParent.LastName1 | titlecase}}
          {{selectedParent.LastName2 | titlecase}} - Documento: {{selectedParent.Document}}
        </div>

        <button class="button button--transfer button--green"
          [ngClass]="!selectedParent || isLoading ? 'button--disabled' : ''" (click)="parentTransformRequest()">
          Enviar solicitud
        </button>

        <div class="warning-box">
          <div class="warning-box__content warning-box__content--center">
            <em class="icon-i-late-course-directive warning-icon"></em>
            <p>La transferencia será exitosa una vez que el solicitante la acepte</p>
          </div>
        </div>
      </div>
    </div> -->

    <!-- TRANSFER REQUESTS -->
    <!-- <div class="transfer-modal__container" *ngIf="showChildrenModal">
      <div class="transfer-modal">
        <div class="transfer-modal__header-container">
          <div class="transfer-modal__header">
            <h2 class="transfer-modal__title">Solicitud de transferencia</h2>
            <button (click)="showChildrenModal = false" class="button"><em
                class="icon-i-close close-icon"></em></button>
          </div>
          <span class="transfer-modal__subtitle">El usuario jaime.escom.edu.co desea transferir al estudiante</span>
        </div>

        <div class="children-transfer__list">
          <div class="transfer-user" *ngFor="let child of childrenToAccept">
            <div class="transfer-user__content">
              <img [src]="child.studentUser.Photo" alt="" class="transfer-user__image">
              <div class="transfer-user__data">
                <span class="transfer-user__data-name">
                  {{child.studentUser.Name1 | titlecase}}
                  {{child.studentUser.Name2 | titlecase}}
                  {{child.studentUser.LastName1 | titlecase}}
                  {{child.studentUser.LastName2 | titlecase}}
                  {{child.studentUser.Document}}
                </span>
                <span class="transfer-user__data-document">
                  Document: {{child.studentUser.UserDocuments[0].Document}}
                </span>
                <a href="" class="transfer-user__data-cv"><em class="icon-i-view-details cv-icon"></em>Ver hoja de
                  vida</a>
              </div>
            </div>
            <div class="transfer-user__actions">
              <button class="button button--green button--sm" [ngClass]="isLoading ? 'disabled' : ''"
                (click)="acceptChild(child, true)">Aceptar</button>
              <button class="button button--red button--sm" [ngClass]="isLoading ? 'disabled' : ''"
                (click)="acceptChild(child, false)">Rechazar</button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </ng-container>

  <div class="table-dropdown" [ngStyle]="dropdownPosition" *ngIf="showAttendantDropdown">
    <find-user-dropdown [placeholder]="'Buscar por documento'"
      [relationships]="relationships"
      (onItemClick)="userToAttendant = $event"
      (onSelectRelationship)="selectedRelationship = $event"
      (onConfirm)="assignAttendant()">
    </find-user-dropdown>
  </div>

  <ng-container *ngIf="showRegisterForm">
    <request-children-form (onCloseForm)="refreshUsers()" [countries]="countries" [currentUserId]="currentUser.id"
      [currentUserEmail]="currentUser.email">
    </request-children-form>
  </ng-container>
</section>
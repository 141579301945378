<div class="approval__item-container">
  <div class="approval__subject-title">
    <span class="approval-text approval-text--bold approval-text--title" title="{{ userSubject.subjectGroup.subjects.NameSubject }}"
      (click)="showAdditionalData = true;" [ngStyle]="{'cursor': !showAdditionalData ? 'pointer' : 'text'}">
      {{ userSubject.subjectGroup.subjects.NameSubject }}
    </span>

    <div class="approval__subject-actions">
      <ng-container *allowedRoles="[1,6]">
        <button class="button button--edit-note" (click)="createSubjectGroup()"
          *ngIf="(userSubject.FinalRecord < noteToApprove) && showAdditionalData">
          Guardar nota
        </button>
      </ng-container>

      <span class="close-subject" *ngIf="showAdditionalData"
        (click)="showAdditionalData = false; noteValue = 0;">
        <i class="icon-i-close"></i>
      </span>
    </div>
  </div>

  <div class="approval__item" [ngStyle]="setColumns()" [ngClass]="showAdditionalData ? 'approval__item--active' : ''">
    <div class="approval__list-subject">
      <!-- Subject col -->
      <span class="approval-text approval-text--blue">Créditos: {{ userSubject.subjectGroup.subjects.Creditos || ''}}</span>
      <span class="approval-text">
        PROFESOR:
        {{ userSubject.subjectGroup.userapp?.Name1 || ''}}
        {{ userSubject.subjectGroup.userapp?.Name2 || ''}}
        {{ userSubject.subjectGroup.userapp?.LastName1 || ''}}
        {{ userSubject.subjectGroup.userapp?.LastName2 || ''}}
      </span>
      <span class="approval-text approval-text--italic">e-mail:
        {{ userSubject.subjectGroup.userapp?.email }}
      </span>
      <span class="approval-text">
        GRUPO:
        {{ userSubject.subjectGroup.NameSubjectGroup }}
      </span>
    </div>

    <!-- Absences col -->
    <span class="approval-text approval-text--center">{{ userSubject.Absences || 0 }}</span>
    <!-- Final record col -->
    <span class="approval-text approval-text--center">{{ userSubject.FinalRecord || '-'}}</span>
    <!-- Enable record col -->
    <ng-container *allowedRoles="[1,6]">
      <input type="number" min="0" max="5" class="approval-input" (change)="valueCheck($event)" 
        *ngIf="(userSubject.FinalRecord < noteToApprove) && showAdditionalData">
    </ng-container>
    <span class="approval-text approval-text--center"
      *ngIf="(userSubject.FinalRecord >= noteToApprove) || (showStatus && !showAdditionalData)">{{ noteValue || '-' }}</span>
    <!-- Status col -->
    <div class="approval-status approval-text" *ngIf="showStatus">
      <span></span> Perdida
    </div>
  </div>

  <div class="list-subject__info" [hidden]="!showAdditionalData">
    <!-- MIDTERM -->
    <div class="list-subject__info-container">
      <div class="list-subject__header">
        <span class="approval-text">NOMBRE DE LA CATEGORÍA</span>
        <span class="approval-text approval-text--center">PORCENTAJE</span>
        <span class="approval-text approval-text--center">NOTAS DEL ESTUDIANTE</span>
        <span class="approval-text approval-text--center">DE ACUERDO</span>
      </div>

      <div class="list-subject__body-container">
        <ng-container *ngFor="let term of userSubject.userRecord">
          <div class="list-subject__row" *ngIf="!term.IsModify">
            <span class="approval-text approval-text--bold">{{ term.midTerm.NameMid }}</span>
            <span class="approval-text approval-text--bold approval-text--center">{{ term.midTerm.Porcent }}%</span>
            <span class="approval-text approval-text--bold approval-text--center">{{ term.ParcialRecord }}</span>
            <span class="approval-text approval-text--bold approval-text--center">{{ term.IsAccording ? 'Si' : 'No' }}</span>
          </div>
        </ng-container>
      </div>
    </div>

    <!-- HISTORY -->
    <div class="list-subject__info-container" *ngIf="showHistory">
      <div class="list-subject__header">
        <span class="approval-text">NOMBRE DE LA CATEGORÍA</span>
        <span class="approval-text approval-text--center">PORCENTAJE</span>
        <span class="approval-text approval-text--center">NOTAS DEL ESTUDIANTE</span>
        <span class="approval-text approval-text--center">DE ACUERDO</span>
      </div>

      <div class="list-subject__body-container">
        <ng-container *ngFor="let term of userSubject.userRecord">
          <div class="list-subject__row" *ngIf="term.IsModify">
            <span class="approval-text approval-text--bold">{{ term.midTerm.NameMid }}</span>
            <span class="approval-text approval-text--bold approval-text--center">{{ term.midTerm.Porcent }}%</span>
            <span class="approval-text approval-text--bold approval-text--center">{{ term.ParcialRecord }}</span>
            <span class="approval-text approval-text--bold approval-text--center">{{ term.IsAccording ? 'Si' : 'No' }}</span>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="approval-button_list">
      <button class="button button--history" (click)="showHistory = !showHistory" *ngIf="hasHistory">
        <i class="icon-i-clock"></i>
        {{ showHistory ? 'Ocultar' : 'Ver' }} historial
      </button>
      <button class="button button--delete" type="button"
        *allowedRoles="[1,6]"
        (click)="deleteAction.emit( { userSubjectInfo:userSubject, subjectInfo:userSubject.subjectGroup.subjects } )">
        Eliminar materia
      </button>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DispatchOrderI } from '../models/dispatchOrder/dispatch-order';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentOrderService {

  constructor(private _httpService: HttpService) { }

  private _userRoles: any[] = [];

  public get getUserRoles() {
    return this._userRoles;
  }

  public set setUserRoles(value: any[]) {
    this._userRoles = value;
  }

  getSchools() {
    return this._httpService.httpGet('/Schools');
  }

  getDocumentTypes() {
    return this._httpService.httpGet('/TypeDocuments');
  }

  getReceipts(filter?: string): Observable<DispatchOrderI[]> {
    let newFilter = "";
    if (filter) newFilter = `?filter=${filter}`;
    return this._httpService.httpGet(`/DispatchOrders${newFilter}`);
  }

  generateReport(filter:Record<string, any>): Observable<{receipts: DispatchOrderI[]}> {
    return this._httpService.httpPost(`/DispatchOrders/generateReport`, filter);
  }

  createOrUpdateReceipt(data: any): Observable<DispatchOrderI> {
    return this._httpService.httpPost('/DispatchOrders', data);
  }

  getCurrentUser(id: any): Observable<any> {
    let filter = `?filter=${JSON.stringify(
      { include: ['UserDocuments', 'roleMappings', 'roles'] })
      }`;
    return this._httpService.httpGet(`/Userapps/${id + filter}`);
  }

  getTotalReceipts(filter?: string): Observable<any> {
    let newFilter = "";
    if (filter) newFilter = `?where=${filter}`;
    return this._httpService.httpGet(`/DispatchOrders/count${newFilter}`);
  }

  getUserInfo(filter: string) {
    const newFilter = `?filter=${filter}`;
    return this._httpService.httpGet(`/ViewUserInfos/${newFilter}`);
  }

  parentToStudents(filter: string) {
    const newFilter = `?filter=${filter}`;
    return this._httpService.httpGet(`/ParentsToStudents/${newFilter}`);
  }

  getParentsToStudents(filter: string) {
    const newFilter = `?where=${filter}`;
    return this._httpService.httpGet(`/ParentsToStudents/${newFilter}`);
  }

  deleteReceipt(id: number) {
    return this._httpService.httpDelete(`/DispatchOrders/${id}`);
  }

  patchReceipt(id: number, data:any) {
    return this._httpService.httpPatch(`/DispatchOrders/${id}`, data);
  }

  setNotificationQueue(data: any) {
    let url: string = `/NotificationQueues`
    return this._httpService.httpPost(url, data);
  }
}

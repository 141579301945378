import { AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import { RegisterChildrenService } from '../../services/register-children/register-children.service';
import { EMPTY, Subscription, forkJoin, map, switchMap, take } from 'rxjs';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'landing-register-children',
  templateUrl: './register-children.component.html',
  styleUrls: ['./register-children.component.css']
})
export class RegisterChildrenComponent implements OnInit, AfterViewInit {

  constructor(
    private _registerChildrenService: RegisterChildrenService,
    private _route: Router,
  ) {
    //
  }


  //@ViewChild('newParentInput') newParentInput: ElementRef;

  public showRegisterForm: boolean = false;
  public showStudentList: boolean = true;

  //public showTransferModal: boolean = false;
  //public parentList: ParentI[] = [];
  //public selectedParent: ParentI | null = null;
  //public showParentList: boolean = false;
  //public childToTransfer: any = null;
  //public showChildrenModal: boolean = false;
  //public childrenToAccept: any[] = [];

  public children: any[] = [];
  public documentTypes: any[] = [];
  public currentUser: any = null;
  public isLoading: boolean = false;
  public assigningMainRelationship:boolean = false;

  //public alreadyAssignedAttendants: any[] = [];
  public userToAttendant: any = null;
  public responsibleUser: any = null;
  public assigningAttendant:boolean = false;

  public dropdownPosition:any = {};
  public showAttendantDropdown:boolean = false;

  public relationships: any[] = [];
  public selectedRelationship: string | null = null;
  public countries:any[] = [];
  private _parentToStudentsId:number = 0;
  private _subscription: Subscription = new Subscription();

  ngOnInit(): void {
    this.isLoading = true;
    const userId: any = localStorage.getItem('currentUser');
    const currentUserId = JSON.parse(userId);

    this._registerChildrenService.getCurrentUser(currentUserId).pipe(
      switchMap((user: any) => {
        this.currentUser = user[0];

        const attendantRole = user[0].roleMappings.find(e => e.roleId === 29);
        if (!attendantRole) {
          this._route.navigate(['user/my-account'])
          return EMPTY;
        }

        return forkJoin({
          countries:this._registerChildrenService.getCountries(),
          relationships: this._registerChildrenService.getRelationships(),
          documents: this._registerChildrenService.getDocumentTypes(),
          children: this._registerChildrenService.getChildren(this.currentUser.id),
          responsibleFor: this._registerChildrenService.getChildFromResponsible(this.currentUser.id),
        })
      }
      )).subscribe({
        next: ({ documents, children, responsibleFor, relationships, countries }: any) => {
          this.countries = countries;
          this.relationships = relationships;
          this.responsibleUser = responsibleFor[0];
          this.filterUsers(children);
          this.documentTypes = documents;
          this.isLoading = false;
        }, error: (err) => {
          console.log(err)
          this.isLoading = false;
        }
      })
  }

  ngAfterViewInit(): void {
    /* fromEvent<Event>(this.newParentInput.nativeElement, 'keyup').pipe(
      map((event: Event) => {
        return (event.target as HTMLInputElement).value;
      }),
      debounceTime(300),
      switchMap((value: string) => {
        return value.length > 3 ? this._registerChildrenService.finUserByDocument(value) : of([])
      })
    ).subscribe({
      next: (resp: ParentI[]) => {
        this.parentList = resp;
      }, error: (err) => {
        console.log(err);
      }
    }) */
  }

  /**
   * Get type document name
   * @param id
   * @returns
   */
  public getTypeDocumentName(id: number | undefined) {
    if(!id) return 'Datos incompletos';
    return this.documentTypes.find(e => e.id === id).NameTypeDocument;
  }

  //Open register form
  public openRegisterForm() {
    this.showRegisterForm = true;
    this.showStudentList = false;
    //this.showTransferModal = false;
  }

  /**
   * Called when the user closes the registration form or creates a child.
   */
  public refreshUsers() {
    this.isLoading = true;
    this.showRegisterForm = false;
    this.showStudentList = true;

    //Refresh children list
    this._subscription.unsubscribe();
    this._subscription = this._registerChildrenService.getChildren(this.currentUser.id).subscribe({
      next: (resp) => {
        this.isLoading = false;
        this.filterUsers(resp);
      }, error: (err) => {
        console.log(err)
        this.isLoading = false;
      }
    });
  }

  //Get child to transfer
  /* public getChildToTransfer(child: any) {
    this.showTransferModal = true
    this.childToTransfer = child;
  } */

  //Get parent to transfer
  /* public getParenToTransfer(parent: ParentI) {
    this.selectedParent = parent;
    this.showParentList = false;
  } */

  //Close the transfer modal
  /* public closeParenTransformModal() {
    //this.showTransferModal = false;
    this.selectedParent = null;
    this.parentList = [];a
    this.newParentInput.nativeElement.value = '';
  } */

  //Accept or reject users
  /* public acceptChild(child: any, isAccepted: boolean) {
    if (this.isLoading)
      return;

    const data = {
      isAccepted,
      ParentUserId: isAccepted ? this.currentUser.id : child.ParentUserId,
      TransferParentUserId: isAccepted ? null : child.TransferParentUserId
    }

    this.isLoading = true;
    this._registerChildrenService.patchChild(data, child.id).pipe(
      switchMap(() => {
        const formatFullName = (user: any) => `${user.Name1} ${user.Name2} ${user.LastName1} ${user.LastName2}`;
        const childName = formatFullName(child.studentUser);
        const parentName = formatFullName(child.parentUser);
        const senderName = formatFullName(this.currentUser);

        const parentEmail = child.parentUser.email;
        const senderEmail = this.currentUser.email;

        const stateMessage = `El usuario "${senderName}" ${isAccepted ? 'aceptó' : 'rechazó'} al estudiante "${childName}"`;
        const receivedRequestMessage = `Acabas de ${isAccepted ? 'aceptar' : 'rechazar'} al estudiante "${childName}" en solicitud de "${parentName}"`;

        return forkJoin({
          notify: this.sendNotification(
            [parentEmail, senderEmail],
            [{ message: stateMessage }, { message: receivedRequestMessage }],
            ["Solicitud de transferencia"]
          ),
          children: this._registerChildrenService.getChildren(this.currentUser.id)
        }).pipe(
          map(({ notify, children }) => ({ notify, children }))
        )
      })
    ).subscribe({
      next: ({ notify, children }) => {
        this.filterUsers(children);
        this.isLoading = false;
      }, error: (err) => {
        console.log(err);
        this.isLoading = false;
      }
    })
  } */

  //Sen transfer request
  /* public parentTransformRequest() {
    if (!this.selectedParent || this.isLoading)
      return;

    const data = {
      isAccepted: null,
      TransferParentUserId: this.selectedParent!.id,
    }

    this.isLoading = true;
    this._registerChildrenService.patchChild(data, this.childToTransfer.id!).pipe(
      switchMap(() => {
        const formatFullName = (user: any) => `${user.Name1} ${user.Name2} ${user.LastName1} ${user.LastName2}`;
        const childName = formatFullName(this.childToTransfer.studentUser);
        const parentName = formatFullName(this.selectedParent!);
        const senderName = formatFullName(this.currentUser);

        const senderEmail = this.currentUser.email;
        const parentEmail = this.selectedParent?.email;

        const transferRequestMessage = `Acabas de enviar una solicitud de transferencia del estudiante "${childName}" a "${parentName}"`;
        const receivedRequestMessage = `Tienes una nueva solicitud de trasferencia de "${senderName}" para el estudiante "${childName}"`;

        return forkJoin({
          notify: this.sendNotification(
            [senderEmail, parentEmail],
            [{ message: transferRequestMessage }, { message: receivedRequestMessage }],
            ["Solicitud de transferencia"]
          ),
          children: this._registerChildrenService.getChildren(this.currentUser.id)
        }).pipe(
          map(({ notify, children }) => ({ notify, children }))
        )
      })
    ).subscribe({
      next: ({ notify, children }) => {
        this.filterUsers(children);
        this.isLoading = false;
        this.closeParenTransformModal();
      }, error: (err) => {
        console.log(err);
        this.isLoading = false;
      }
    })
  } */

  //Send emails
  private sendNotification(targetEmails: string[], messages: { message: string }[], targetName: string[]) {
    const data = {
      emailsTo: JSON.stringify(targetEmails),
      namesTO: JSON.stringify(targetName),
      msg: JSON.stringify(messages),
      timeToSend: new Date().toISOString(),
      isSend: false,
      isSingleMessage: true,
      typeNotification: 'email',
      senderName: null,
      senderEmail: null,
      atachment: null,
      templateFile: 'template.html'
    }

    return this._registerChildrenService.postNotificationQueue(data);
  }

  //Filters accepted and not accepted users
  private filterUsers(children: any[]) {
    //Obtains the users that I have initially created and those that I have accepted.
    this.children = children.filter(e => e.ParentUserId === this.currentUser.id);

    //Stores the users who are already attendants, to ignore them when searching.
    /* this.alreadyAssignedAttendants = children
      .filter(e => e.attendantUser)
      .map(e => ({ id: e.attendedBy })); */

    /* this.childrenToAccept = children.filter(e => (e.TransferParentUserId !== null && e.TransferParentUserId === this.currentUser.id) &&
      e.isAccepted === null
    ); */
  }

  //Assign attendant
  public assignAttendant(){
    if(!this.userToAttendant || this.assigningAttendant)
      return;

    Swal.fire({ 
      title:"Asignando",
      text: 'Por favor espere.',
      allowEscapeKey:false,
      allowOutsideClick:false
    });
    Swal.showLoading();

    this.assigningAttendant = true;

    const data = JSON.stringify({
      attendedBy: this.userToAttendant.id,
      typeRelationshipIDAttended: this.selectedRelationship
    })

    this._registerChildrenService.patchChild(data, this._parentToStudentsId).pipe(
      switchMap( () => this._registerChildrenService.getChildren(this.currentUser.id) ),
      //If I assign myself as a attendant...
      switchMap( (users:any) => this._registerChildrenService.getChildFromResponsible(this.currentUser.id)
        .pipe(
          map(e => ({users, responsibleFor:e}))
        ))
    ).subscribe({
      next:({users, responsibleFor})=>{
        this.responsibleUser = responsibleFor[0];
        this.filterUsers(users);
        Swal.fire({
          icon:'success',
          title: "Asignación exitosa",
          text: "El acudiente se asignó correctamente.",
        })
      },error:(err)=>{
        console.log(err);
        Swal.fire({
          icon: 'error',
          title:"Error al asignar acudiente",
          text: "Por favor, inténtelo de nuevo más tarde o contacte con un administrador."
        });
      },complete:() => this.assigningAttendant = false
    })
  }

  //Set target attendant
  public setDropDownPosition(event: Event,){
    const buttonPosition = (event.target as HTMLElement).getBoundingClientRect();
    const modalLeft = buttonPosition.left;
    const modalTop = buttonPosition.top + buttonPosition.height;

    this.dropdownPosition = {
      left: modalLeft + 60 + 'px',
      top: modalTop + 'px'
    }
  }

  public onSetRelationship(parentsToStudentsId:number){
    this._parentToStudentsId = parentsToStudentsId
    this.showAttendantDropdown = true;
  }

  public setMainRelationship(event:Event, parentsToStudentsId:number){
    const target = event.target as HTMLInputElement;
    this.assigningMainRelationship = true;

    this._registerChildrenService
      .patchChild(
        { typeRelationshipIDParent: target.value },
        parentsToStudentsId
      ).subscribe({
        error: (err) => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al asignar el parentesco.',
            text: 'Por favor, inténtelo de nuevo más tarde o contacte con un administrador.',
          });
        },
        complete: () => this.assigningMainRelationship = false
      });
  }

  changeUser(childInfoSelected) {
    let data = {
      parentToken: localStorage.getItem('accessToken'),
      idStudentUser: childInfoSelected.studentUser.id
    }
    this._registerChildrenService.changePlatformUserToMyChild(data).pipe(take(1)).subscribe({
      next: (response) => {
        console.log('response', response);
        localStorage.setItem("currentUser", childInfoSelected.studentUser.id);
        this._route.navigate(['/user/my-account']);
        window.location.reload();
        window.location.reload();
      }, error: (err) => {
        console.log('error userapp post', err);
        Swal.fire({
          icon:'error',
          title:"Error",
          text: "Si el error persiste, contacte a su administrador."
        });
      }
    });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    const target = (event.target as HTMLElement);
    if (!target.closest('.search-dropdown__container') && !target.classList.contains('button--attendant'))
      this.showAttendantDropdown = false;
  }
}
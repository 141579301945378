<div class="approval__item-container">
  <div class="approval__subject-title">
    <span class="approval-text approval-text--bold approval-text--title"
      title="{{ userSubject.SubjectV2GroupOffer.SubjectV2Offer.nameSubject }}">
      {{ userSubject.SubjectV2GroupOffer.SubjectV2Offer.nameSubject }}
    </span>
  </div>

  <div class="approval__item" [ngStyle]="setColumns()" [ngClass]="showAdditionalData ? 'approval__item--active' : ''">
    <div class="approval__list-subject">
      <!-- Subject col -->
      <span class="approval-text approval-text--blue">Créditos: {{ userSubject.SubjectV2GroupOffer.SubjectV2Offer.creditNumber || ''}}</span>
      <span class="approval-text">
        PROFESOR:
        {{ userSubject.SubjectV2GroupOffer.ProfessorToSubjectGroups?.[0]?.professor?.Name1 || 'n/a'}}
        {{ userSubject.SubjectV2GroupOffer.ProfessorToSubjectGroups?.[0]?.professor?.LastName1 || 'n/a'}}
      </span>
      <span class="approval-text">
        GRUPO: {{ userSubject.SubjectV2GroupOffer.nameSubjectV2GroupOffer }}
      </span>

      <!-- <div class="approval-button_list">
        <ng-container *ngFor="let record of userSubject.UserRecordsV2">
          <ng-container *ngIf="record.IsModify">
            <a href="{{getCompleteUrlFile(record.dateRecord.attachmentLink)}}" target="_blank" rel="noopener"
              class="button button--download">
              <i class="icon-i-download"></i>
              Adjunto
            </a>
          </ng-container>
        </ng-container>
        <button class="button button--delete" type="button"
          *allowedRoles="[1,6]"
          (click)="deleteAction.emit( { userSubjectInfo:userSubject, subjectInfo:userSubject.subjectGroup.subjects } )">
          Eliminar materia
        </button>
      </div> -->
    </div>

    <!-- Absences col -->
    <span class="approval-text approval-text--center">{{ userSubject.totalAbsences || 0 }}</span>
    <!-- Final record col -->
    <span class="approval-text approval-text--center">{{ userSubject.finalRecord || '-'}}</span>
    <!-- Enable record col -->
    <span class="approval-text approval-text--center">-</span>
    <!-- Status col -->
    <div class="approval-status approval-text" *ngIf="showStatus">
      <span></span> Homologada
    </div>
  </div>
</div>
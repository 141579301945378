import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubjectApprovedComponent } from './subject-approved/subject-approved.component';
import { SubjectCertifiedComponent } from './subject-certified/subject-certified.component';
import { SubjectPendingComponent } from './subject-pending/subject-pending.component';
import { SubjectStudyingComponent } from './subject-studying/subject-studying.component';
import { SubjectUnapprovedComponent } from './subject-unapproved/subject-unapproved.component';
import { ApprovalListSuperiorComponent } from './approval-list-superior.component';
import { ApprovalFilterComponent } from '../common/approval-filter/approval-filter.component';
import { ApprovalListHeaderComponent } from '../common/approval-list-header/approval-list-header.component';
import { ApprovalModalComponent } from '../common/approval-modal/approval-modal.component';
import { CommonApprovalModule } from '../common/common-approval.module';

@NgModule({
  declarations: [
    SubjectApprovedComponent,
    SubjectCertifiedComponent,
    SubjectPendingComponent,
    SubjectStudyingComponent,
    SubjectUnapprovedComponent,
    ApprovalListSuperiorComponent,
  ],
  imports: [
    CommonModule,
    CommonApprovalModule
  ],
  exports:[
    ApprovalListSuperiorComponent
  ]
})
export class ApprovalListSuperiorModule { }

import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { concat, EMPTY, forkJoin, fromEvent, Observable, of, Subscription } from "rxjs";
import {
  debounceTime,
  map,
  switchMap,
  startWith,
  distinctUntilChanged,
  toArray,
} from "rxjs/operators";
import { PaymentOrderService } from "src/app/services/payment-order.service";
import { XlsxService } from "src/app/services/xlsx.service";
import { SharedService } from "src/app/services/shared.service";
import { DOCUMENTS } from "src/app/constants/payments";
import {
  swalClose,
  swalError,
  swalLoading,
  swalSuccess,
  swalWarning,
} from "src/utils/alerts";
import { environment } from "src/environments/environment";
import { DispatchOrderI } from "src/app/models/dispatchOrder/dispatch-order";

@Component({
  selector: "upload-payment-order",
  templateUrl: "./upload-payment-order.component.html",
  styleUrls: ["./upload-payment-order.component.css"],
})
export class UploadPaymentOrderComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  constructor(
    private _xlsxService: XlsxService,
    private _paymentOrder: PaymentOrderService,
    private _shareService: SharedService
  ) {}

  @ViewChildren("filterInput") filterInputs: QueryList<ElementRef>;
  @ViewChild("searchBar") searchBar!: ElementRef;

  public SCHOOL_NAME = environment.SCHOOL_NAME;

  public selectAll: boolean = false;
  public selectAllState: "checked" | "unchecked" | "partial" = "unchecked";
  public schools: any[] = [];

  public previewTablePageCounter: number = 2;

  public showDateModal: boolean = false;
  public filteredReceipts: any[] = [];
  public receiptsToDelete: number[] = [];
  public loadingReceipts: boolean = true;
  public loadingUserInfo: boolean = true;

  //#region ✅ FILTERS
  public paymentState: boolean | undefined = undefined;
  public sortList: boolean | undefined = undefined;
  private _fromDate: Date | undefined = undefined;
  private _toDate: Date | undefined = undefined;
  private _searchValue: string = "";
  private _selectedSchools: number[] = [];
  //#endregion

  //#region ⬅️➡️ DATABASE PAGINATION
  public dbTotalPages: number = 0;
  public dbCurrentPage: number = 1;
  public dbItemsPerPage: number = 30;
  public dbTotalItems: number = 0;
  //#endregion

  public currentUser: any = undefined;
  private _subscription: Subscription = new Subscription();
  private _subscriptionSchool: Subscription = new Subscription();

  ngOnInit(): void {
    this.loadingUserInfo = true;

    forkJoin({
      schools: this._paymentOrder.getSchools(),
      user: this._paymentOrder.getCurrentUser("/me"),
    }).subscribe({
      next: ({ schools, documentTypes, user }: any) => {
        this.schools = schools.map((e) => ({
          id: e.id,
          schoolAcronym: e.SchoolAcronim,
        }));
        this.currentUser = user;

        const roleIDs = [...new Set(user.roleMappings.map((e) => e.roleId))];
        const uniqueRoles = [];

        for (const role of user.roles) {
          if (
            roleIDs.includes(role.id) &&
            !uniqueRoles.some((e) => e.id === role.id)
          ) {
            uniqueRoles.push(role);
          }
        }

        this._paymentOrder.setUserRoles = uniqueRoles.map((e) =>
          e.name.toLowerCase()
        );
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
        this.loadingUserInfo = false;
      },
    });
  }

  ngAfterViewInit(): void {
    this._subscriptionSchool =
      this._shareService.sharedyearSchoolFilter.subscribe({
        next: (resp) => {
          if (resp.schools.length > 0) {
            const schools = resp.schools;
            this._selectedSchools = schools.map((e) => e.id);

            //Filter receipts by search input value
            fromEvent<Event>(this.searchBar.nativeElement, "keyup")
              .pipe(
                map(
                  (event: Event) =>
                    (event.target as HTMLInputElement)?.value || ""
                ),
                startWith(""), //Emitting an empty initial value
                debounceTime(300),
                distinctUntilChanged(),
                switchMap((value: string) => {
                  this._searchValue = value;
                  if (value.length > 3 || value === "") {
                    this.loadingReceipts = true;
                    return this.getReceipts(1);
                  }
                  return EMPTY;
                })
              )
              .subscribe({
                next: ({ receipts, totalItems }) => {
                  this.dbCurrentPage = 1;
                  this.filteredReceipts = receipts;
                  this.dbTotalItems = totalItems;
                  this.dbTotalPages = Math.ceil(
                    totalItems / this.dbItemsPerPage
                  );
                  this.loadingReceipts = false;
                },
                error: (err) => console.log(err),
              });
          }
        },
        error: (err) => console.log(err),
      });
  }

  /**
   * Gets the receipt list and set the total pages for pagination
   * @param currentPage
   */
  public applyFilters(currentPage?: number) {
    //Date filter modal
    this.showDateModal = false;
    this.dbCurrentPage = currentPage !== undefined ? currentPage : 1;
    this.loadingReceipts = true;

    this._subscription.unsubscribe();
    this._subscription = this.getReceipts(this.dbCurrentPage).subscribe({
      next: ({ receipts, totalItems }) => {
        this.filteredReceipts = receipts;
        this.dbTotalItems = totalItems;
        this.dbTotalPages = Math.ceil(totalItems / this.dbItemsPerPage);
        this.loadingReceipts = false;
      },
      error: (err) => console.log(err),
    });
  }

  /**
   * Fetches from the database the list of records depending on the pagination, the data of the logged in user and the current number of receipts.
   * @param currentPage
   * @returns
   */
  private getReceipts(
    currentPage: number
  ): Observable<{ receipts: any[]; totalItems: number }> {
    //Clear checkbox selections
    this.clearAllSelections();

    const receiptsFilter = this.setReceiptsFilter(currentPage);
    const receiptsCountFilter = JSON.stringify({
      and: this.generateReceiptsFilters(),
    });

    return forkJoin({
      receipts: this._paymentOrder.getReceipts(receiptsFilter),
      totalReceipts: this._paymentOrder.getTotalReceipts(receiptsCountFilter),
    }).pipe(
      map(({ receipts, totalReceipts }) => ({
        receipts,
        totalItems: totalReceipts.count,
      }))
    );
  }

  /**
   * Clear all selected receipts do be deleted
   */
  private clearAllSelections() {
    this.receiptsToDelete = [];
    this.selectAllState = "unchecked";
    this.selectAll = false;
    const data = this.filteredReceipts.filter(
      (receipt) => !receipt.PaymentState
    );
    data.forEach((e) => (e.checked = false));
  }

  /**
   * Returns a filter for querying the receipts based on the specified parameters.
   *
   * @param currentPage
   * @param value
   * @returns
   */
  private setReceiptsFilter(currentPage: number) {
    const where: any = { and: this.generateReceiptsFilters() };
    const include: any = { userapp: "UserDocuments" };
    const skip: any = (currentPage - 1) * this.dbItemsPerPage;
    const limit: any = this.dbItemsPerPage;
    const order: any = () => {
      if (this.sortList !== undefined) {
        return `DueDate ${this.sortList ? "ASC" : "DESC"}`;
      } else {
        return `id DESC`;
      }
    };

    return JSON.stringify({ where, include, skip, limit, order: order() });
  }

  /**
   * Generates an array of filters based on the specified parameters
   * @param value
   * @returns
   */
  private generateReceiptsFilters(): any[] {
    const filters: any[] = [
      { Code: { inq: [...this._selectedSchools] } },
      { isVisible: true },
    ];

    if (this.paymentState !== undefined)
      filters.push({ PaymentState: this.paymentState });

    if (this._fromDate !== undefined && this._toDate !== undefined)
      filters.push({ DueDate: { between: [this._fromDate, this._toDate] } });

    if (this._searchValue.length > 3)
      filters.push({
        or: [
          { document: { regexp: `/${this._searchValue}/` } },
          { Description: { regexp: `/${this._searchValue}/i` } },
        ],
      });

    return filters;
  }

  /**
   * Clean the filters
   */
  public clearFilters() {
    this.paymentState = undefined;
    this.sortList = undefined;
    this._fromDate = undefined;
    this._toDate = undefined;

    this.searchBar.nativeElement.value = "";
    this._searchValue = "";

    this.filterInputs.forEach((e) => {
      e.nativeElement.value = "";
      e.nativeElement.checked = false;
    });

    this.applyFilters();
  }

  public downloadReport() {
    const pageSize = 4000;
    let skip = 0;
    const allData: any[] = [];
    let totalFetched = 0;

    swalLoading({
      message: "Descargando documento, esto puede tomar un momento.",
    });

    const fetchData = (skip: number) => {
      const where = { and: this.generateReceiptsFilters() };
      const include = [{ userapp: "UserDocuments" }, "dispatchorderConcepts"];
      const order = this.sortList !== undefined ? `DueDate ${this.sortList ? "ASC" : "DESC"}` : `id DESC`;
  
      return this._paymentOrder.generateReport({
        filters: { where, include, order, limit: pageSize, skip },
      }).pipe(
        map(({receipts}) => receipts)
      );
    };


    const processAndAccumulateData = (receipts: DispatchOrderI[]) => {
      const processedData = receipts.map((item: DispatchOrderI) => ({
        ODC: item.id.toString(),
        UNIDAD: this.getSchoolAcronym(item),
        CONVENIO: item.EcollectValue.toString(),
        CODIGO: "N/A",
        DESCRIPCION: item.Description,
        "APELLIDOS Y NOMBRES COMPLETOS": item.FirstName,
        DOCUMENTO: this.getDocumentType(item),
        "NUMERO DOCUMENTO": item.document,
        DIRECCIÓN: item.Address,
        "CODIGO MUNICIPIO": item.municipalCode,
        "CORREO ELECTRONICO1": item.firsEmail,
        "CORREO ELECTRONICO 2": item.lastEmail,
        TELEFONO: item.Telephone.toString(),
        "VALOR A CONSIGNAR": (item.paymentDate1 || "").toString(),
        "POSICION CATALOGO BIENES Y SERVICIOS SIIF": item.AssetsAndServicesCatalog,
        EDUCACION: item.Education,
        "FECHA RECIBO": item.issueDate,
        ESTADO: item.PaymentState ? "Pagado" : "Sin pagar",
        "FECHA DE PAGO": item.paidDate,
      }));
  
      allData.push(...processedData);
      totalFetched += receipts.length;
    };

    // Recursuve fetch
    const fetchAllData = (): Observable<any> => {
      return fetchData(skip).pipe(
        switchMap((receipts) => {
          if (receipts.length > 0) {
            processAndAccumulateData(receipts);
            skip += pageSize; // Increase for the next chunk
            return fetchAllData(); // Call the next chunk
          }
          return of(null); // If there is no more data
        })
      );
    };

    fetchAllData().subscribe({
      complete: () => {
        const currentDate = new Date();
        const localDateString = currentDate.toLocaleDateString();
        const localTimeString = currentDate.toLocaleTimeString();
        const formattedDateTime = `${localDateString}-${localTimeString}`;
  
        const isPayed =
          typeof this.paymentState !== "undefined"
            ? this.paymentState
              ? "PAGADOS"
              : "NO_PAGADOS"
            : "";

        const fileName = `(${formattedDateTime}) RECIBOS${isPayed}`;
  
        this._xlsxService.exportToExcel(allData, fileName.replace(/\s/g, "_"), pageSize, 'Página');
        swalClose();
        console.log(`Descarga completada. Total registros: ${totalFetched}`);
      },
      error: (err) => {
        swalClose();
        console.error("Error durante la descarga:", err);
      },
    });

   /*  const where = { and: this.generateReceiptsFilters() };
    const include = [{ userapp: "UserDocuments" }, "dispatchorderConcepts"];
    const order = () => {
      if (this.sortList !== undefined)
        return `DueDate ${this.sortList ? "ASC" : "DESC"}`;

      return `id DESC`;
    }; */

    /* this._paymentOrder
      .generateReport({filters: { where, include, order: order() }})
      .pipe(
        switchMap(({receipts}) => {
          const getSchoolAcronym = (item: any) => {
            const school = this.schools.find((e) => e.id == item.Code);
            return school ? school.schoolAcronym : "";
          };

          const getDocumentType = (item: any) => {
            const document = DOCUMENTS.find((e) => e.id == item.documentType);
            return document ? document.documentAcronym : "";
          };

          if (this.SCHOOL_NAME === "CEDOC") {
            return of(
              receipts.map((item: any) => ({
                ODC: item.id.toString(),
                UNIDAD: getSchoolAcronym(item),
                CONVENIO: item.EcollectValue.toString(),
                CODIGO: "N/A",
                DESCRIPCION: item.Description,
                "APELLIDOS Y NOMBRES COMPLETOS": item.FirstName,
                DOCUMENTO: getDocumentType(item),
                "NUMERO DOCUMENTO": item.document,
                DIRECCIÓN: item.Address,
                "CODIGO MUNICIPIO": item.municipalCode,
                "CORREO ELECTRONICO1": item.firsEmail,
                "CORREO ELECTRONICO 2": item.lastEmail,
                TELEFONO: item.Telephone.toString(),
                "VALOR A CONSIGNAR": (item.paymentDate1 || "").toString(),
                "POSICION CATALOGO BIENES Y SERVICIOS SIIF":
                  item.AssetsAndServicesCatalog,
                EDUCACION: item.Education,
                "FECHA RECIBO": item.issueDate,
                ESTADO: item.PaymentState ? "Pagado" : "Sin pagar",
                "FECHA DE PAGO": item.paidDate,
              }))
            );
          }

          const observables = [];
          for (let i = 0; i < receipts.length; i++) {
            const item: any = receipts[i];
            const extraData = JSON.parse(item.extraData);
            if (!extraData) continue;

            const parentData = {
              "Tipo Identificación Acudiente": getDocumentType(item),
              "Identificación Acudiente": item.document,
              Liceo: getSchoolAcronym(item),
              "Nombres completos estudiantes": extraData.estudiante_nombre,
              Usuario_Email_Casa: item.firsEmail || item.lastEmail,
              Usuario_Direccion_Casa: item.Address,
              Usuario_Telefono_Casa: item.Telephone,
              Usuario_Telefono_Celular: "",
              "Ciclo mes factura": extraData.ciclo_pago,
              "No. Factura": item.id.toString(),
              Concepto: extraData.concepto,
              "Suma de Valor pagado detalle": item.TotalPayment,
            };

            

            if (item.dispatchorderConcepts.length === 0) {
              observables.push(parentData);
              continue;
            }

            const userObservables = item.dispatchorderConcepts.map(
              (paidToInfo) => {
                const paidTo = JSON.parse(paidToInfo.paidTo);
                return (Array.isArray(paidTo) ? paidTo : [paidTo]).map(
                  (document) => {
                    return this._paymentOrder
                      .getUserInfo(
                        JSON.stringify({
                          where: { Document: document },
                        })
                      )
                      .pipe(
                        map((user: any) => ({
                          "Tipo Identificación Acudiente":
                            getDocumentType(item),
                          "Identificación Acudiente": item.document,
                          Liceo: getSchoolAcronym(item),
                          "Nombres completos estudiantes": user.Name1,
                          Usuario_Email_Casa: item.firsEmail || item.lastEmail,
                          Usuario_Direccion_Casa: item.Address,
                          Usuario_Telefono_Casa: item.Telephone,
                          Usuario_Telefono_Celular: "HIJO",
                          "Ciclo mes factura": extraData.ciclo_pago,
                          "No. Factura": item.id.toString(),
                          Concepto: paidToInfo.description,
                          "Suma de Valor pagado detalle": paidToInfo.value,
                        }))
                      );
                  }
                );
              }
            );

            observables.push(...[of(parentData), ...userObservables.flat()]);
          }

          if (observables.length === 0) return of([]);

          return concat(...observables).pipe(toArray());
        })
      )
      .subscribe({
        next: (selectedFields: any[]) => {
          if (selectedFields.length === 0) return;

          const currentDate = new Date();
          const localDateString = currentDate.toLocaleDateString();
          const localTimeString = currentDate.toLocaleTimeString();
          const formattedDateTime = `${localDateString}-${localTimeString}`;

          const isPayed =
            typeof this.paymentState !== "undefined"
              ? this.paymentState
                ? "PAGADOS"
                : "NO_PAGADOS"
              : "";
          const fileName = `(${formattedDateTime}) RECIBOS${isPayed}`;
          this._xlsxService.exportToExcel(
            selectedFields,
            fileName.replace(/\s/g, "_")
          );
          swalClose();
        },
        complete: () => swalClose(),
        error: (err) => console.log(err),
      }); */
  }

  private getSchoolAcronym(item: any): string {
    const school = this.schools.find((e) => e.id == item.Code);
    return school ? school.schoolAcronym : "";
  }
  
  private getDocumentType(item: any): string {
    const document = DOCUMENTS.find((e) => e.id == item.documentType);
    return document ? document.documentAcronym : "";
  }

  //Gets the value of the input ToDate
  public getToDate(date: any) {
    this._toDate = new Date(date.target.value);
  }

  //Gets the value of the input FromDate
  public getFromDate(date: any) {
    this._fromDate = new Date(date.target.value);
  }

  //Temporarily stores in a list the receipt id of the receipt to be deleted
  public setReceiptsToDelete(receipt: any) {
    if (receipt.PaymentState === true) return;

    if (!this.receiptsToDelete.includes(receipt.id))
      this.receiptsToDelete.push(receipt.id);
    else
      this.receiptsToDelete.splice(
        this.receiptsToDelete.indexOf(receipt.id),
        1
      );

    this.updateSelectAllState();
  }

  //Select all receipts to be deleted
  public selectAllReceipt() {
    const data = this.filteredReceipts.filter(
      (receipt) => !receipt.PaymentState
    );
    const allChecked = data.every((receipt) => receipt.checked);
    this.selectAllState = allChecked ? "unchecked" : "checked";

    data.forEach(
      (receipt) => (receipt.checked = this.selectAllState === "checked")
    );
    this.receiptsToDelete =
      this.selectAllState === "checked" ? data.map((e) => e.id) : [];
  }

  //Update the select all visual state
  private updateSelectAllState() {
    const data = this.filteredReceipts.filter(
      (receipt) => !receipt.PaymentState
    );
    const allChecked = data.every((receipt) => receipt.checked);
    const someChecked = data.some((receipt) => receipt.checked);

    this.selectAllState = allChecked
      ? "checked"
      : someChecked
      ? "partial"
      : "unchecked";
  }

  //Disable receipts from database
  public deleteReceipts() {
    if (this.receiptsToDelete.length === 0) return;

    swalWarning({
      title: "Advertencia",
      message:
        "Esta acción eliminará los recibos seleccionados. ¿Estás seguro de que deseas continuar?",
      confirmCallback: () => {
        swalLoading({ message: "Eliminando..." });

        const data = {
          isVisible: false,
          deletedBy: this.currentUser.id,
          deletedAt: new Date().toISOString(),
        };

        const receiptsObservable = this.receiptsToDelete.map((e) =>
          this._paymentOrder.patchReceipt(e, data)
        );

        forkJoin([...receiptsObservable]).subscribe({
          complete: () => {
            swalSuccess({ message: "Eliminado satisfactoriamente." });
            this.applyFilters(); //Refresh list of receipt
          },
          error: (err: Error) => console.log(err),
        });
      },
    });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
    this._subscriptionSchool.unsubscribe();
  }
}

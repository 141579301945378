import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserSubjectIV2 } from 'src/app/models/approvalModels/approval-models-v2';
import { SubjectGroupI, SubjectsI, UserSubjectI } from 'src/app/models/approvalModels/approvalModels';

@Component( {
  selector: 'subject-approved',
  templateUrl: './subject-approved.component.html',
  styleUrls: [ './subject-approved.component.css' ]
} )
export class SubjectApprovedComponent implements OnInit {

  //Sends a notification when delete button is clicked
  @Output( 'deleteAction' ) deleteAction: EventEmitter<any> = new EventEmitter();
  @Input( 'showStatus' ) showStatus: boolean = false;

  //obtains the userSubject data
  private _userSubject: UserSubjectIV2 = {} as UserSubjectIV2;
  @Input( 'userSubject' ) set userSubject( value: UserSubjectIV2 ) {
    this._userSubject = value;
    if ( value === undefined ) return;
    // this.hasHistory = value.UserRecordsV2?.some( e => e.IsModify === true )!;
  }

  get userSubject() {
    return this._userSubject;
  }
  //allows to display or hide the additional information of the subject
  public showAdditionalData: boolean = false;

  //allows to enable or disable the history view
  public showHistory: boolean = false;

  //if there is a history then it will enable the view history button
  public hasHistory: boolean = false;

  constructor () {
    //
  }

  ngOnInit(): void {
    //
  }

  /**
   * Returns an object with styles for a CSS Grid layout.
   * @returns
   */
  public setColumns() {
    return {
      display: 'grid',
      'grid-template-columns': `3fr repeat(${ this.showStatus ? '4' : '3' }, 1fr)`,
      'align-items': 'center',
    }
  }
}

<div class="approval__item-container">
  <div class="approval__subject-title">
    <span class="approval-text approval-text--bold approval-text--title"
      (click)="showAdditionalData = true;"
      [ngStyle]="{'cursor': !showAdditionalData ? 'pointer' : 'text'}">
      {{ userSubject.SubjectV2GroupOffer.SubjectV2Offer.nameSubject }}
    </span>

    <span class="close-subject" *ngIf="showAdditionalData" (click)="showAdditionalData = false;">
      <i class="icon-i-close"></i>
    </span>
  </div>

  <div class="approval__item" [ngStyle]="setColumns()" [ngClass]="{'approval__item--active' : showAdditionalData}">
    <!-- Subject col -->
    <div class="approval__list-subject">
      <span class="approval-text approval-text--blue">Créditos: {{ userSubject.SubjectV2GroupOffer.SubjectV2Offer.creditNumber || ''}}</span>
      <span class="approval-text">
        PROFESOR:
        {{ userSubject.SubjectV2GroupOffer.ProfessorToSubjectGroups?.[0]?.professor?.Name1 || 'n/a'}}
        {{ userSubject.SubjectV2GroupOffer.ProfessorToSubjectGroups?.[0]?.professor?.LastName1 || 'n/a'}}
      </span>
      <span class="approval-text approval-text--italic">e-mail:
        {{ userSubject.SubjectV2GroupOffer.ProfessorToSubjectGroups?.[0]?.professor?.email || 'n/a'}}
      </span>
      <span class="approval-text">
        GRUPO: {{ userSubject.SubjectV2GroupOffer.nameSubjectV2GroupOffer }}
      </span>
    </div>

    <!-- Absences col -->
    <span class="approval-text approval-text--center">{{ userSubject.totalAbsences || 0 }}</span>
    <!-- Final record col -->
    <span class="approval-text approval-text--center">{{ userSubject.finalRecord || '-'}}</span>
    <!-- Enable record col -->
    <span class="approval-text approval-text--center">-</span>
    <!-- Status col -->
    <div class="approval-status approval-text" *ngIf="showStatus">
      <span></span> Aprobada
    </div>
  </div>

  <div class="list-subject__info" [hidden]="!showAdditionalData">
    <!-- MIDTERM -->
    <div class="list-subject__info-container">
      <div class="list-subject__header">
        <span class="approval-text">NOMBRE DE LA CATEGORÍA</span>
        <span class="approval-text approval-text--center">PORCENTAJE</span>
        <span class="approval-text approval-text--center">NOTAS DEL ESTUDIANTE</span>
        <span class="approval-text approval-text--center">DE ACUERDO</span>
      </div>

      <div class="list-subject__body-container">
        <ng-container *ngFor="let term of userSubject.UserRecordsV2">
          <div class="list-subject__row" > <!-- *ngIf="!term.IsModify" -->
            <span class="approval-text approval-text--bold">{{ term.MidTermV2.nameMid }}</span>
            <span class="approval-text approval-text--bold approval-text--center">{{ term.MidTermV2.percentage }}%</span>
            <span class="approval-text approval-text--bold approval-text--center">{{ term.parcialRecord }}</span>
            <span class="approval-text approval-text--bold approval-text--center">{{ term.isAccording ? 'Si' : 'No' }}</span>
          </div>
        </ng-container>
      </div>
    </div>

    <!-- HISTORY -->
     <!-- TODOD: IS MODIFY DOES NOT EXIST -->
    <!-- <div class="list-subject__info-container" *ngIf="showHistory">
      <div class="list-subject__header">
        <span class="approval-text">NOMBRE DE LA CATEGORÍA</span>
        <span class="approval-text approval-text--center">PORCENTAJE</span>
        <span class="approval-text approval-text--center">NOTAS DEL ESTUDIANTE</span>
        <span class="approval-text approval-text--center">DE ACUERDO</span>
      </div>

      <div class="list-subject__body-container">
        <ng-container *ngFor="let term of userSubject.userRecord">
          <div class="list-subject__row" *ngIf="term.IsModify">
            <span class="approval-text approval-text--bold">{{ term.midTerm.NameMid }}</span>
            <span class="approval-text approval-text--bold approval-text--center">{{ term.midTerm.Porcent }}%</span>
            <span class="approval-text approval-text--bold approval-text--center">{{ term.ParcialRecord }}</span>
            <span class="approval-text approval-text--bold approval-text--center">{{ term.IsAccording ? 'Si' : 'No' }}</span>
          </div>
        </ng-container>
      </div>
    </div> -->

    <!-- <div class="approval-button_list">
      <button class="button button--history" (click)="showHistory = !showHistory" *ngIf="hasHistory">
        <i class="icon-i-clock"></i>
        {{ showHistory ? 'Ocultar' : 'Ver' }} historial
      </button>

      <button class="button button--delete" type="button"
        *allowedRoles="[1,6]"
        (click)="deleteAction.emit( { userSubjectInfo:userSubject, subjectInfo:userSubject.SubjectV2GroupOffer.subjects } )">
        Eliminar materia
      </button>
    </div> -->
  </div>
</div>

<div class="approval__item-container">
  <div class="approval__subject-title">
    <span class="approval-text approval-text--bold approval-text--title">
      {{ subjectGroup.SubjectV2Offer.nameSubject }}
    </span>
  </div>

  <div class="approval__item" [ngStyle]="setColumns()" [ngClass]="showAdditionalData ? 'approval__item--active' : ''">
    <div class="approval__list-subject">
      <!-- Subject col -->
      <span class="approval-text approval-text--blue">Créditos: {{ subjectGroup.SubjectV2Offer.creditNumber || ''}}</span>
      <span class="approval-text">PROFESOR:
        {{ subjectGroup.ProfessorToSubjectGroups?.[0]?.professor?.Name1 || 'n/a'}}
        {{ subjectGroup.ProfessorToSubjectGroups?.[0]?.professor?.LastName1 || 'n/a'}}
      </span>
      <span class="approval-text">GRUPO:
        {{ subjectGroup.nameSubjectV2GroupOffer }}
      </span>
    </div>

    <!-- Absences col -->
    <span class="approval-text approval-text--center">N/A</span>
    <!-- Final record col -->
    <ng-container *allowedRoles="[1,6]; then homologBtn; else homologText"></ng-container>
    <!-- Enable record col -->
    <span class="approval-text approval-text--center">-</span>
    <!-- Status col -->
    <div class="approval-status approval-text" *ngIf="showStatus">
      <span></span> Pendiente
    </div>
  </div>

  <ng-template #homologBtn>
    <div class="homolog-actions">
      <button class="button button--enable" type="button"
        (click)="subjectActions.emit( {showModal:true, subjectInfo:subjectGroup.SubjectV2Offer } )">
        Homologar
      </button>
     <!--  <button class="button button--delete" type="button"
        (click)="deleteAction.emit( { showModal:true, userSubjectInfo:subjects, subjectInfo:subjects } )">
        Eliminar
      </button> -->
    </div>
  </ng-template>

  <ng-template #homologText>
    <p class="approval-text approval-text--center">-</p>
  </ng-template>
</div>

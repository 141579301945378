import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { map, Subscription, switchMap } from 'rxjs';
import { AcademicaAreaV2I, SubjectV2GroupOffer, UserSubjectIV2 } from 'src/app/models/approvalModels/approval-models-v2';
import {
  SubjectsI,
  UserCoursesI,
  UserI,
  UserSubjectI,
} from 'src/app/models/approvalModels/approvalModels';
import { ApprovalService } from 'src/app/services/approval/approval.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'celic-approval-list-formal',
  templateUrl: './celic-approval-list-formal.component.html',
  styleUrls: ['./celic-approval-list-formal.component.css'],
})
export class CelicApprovalListFormalComponent implements OnInit, OnDestroy {
  constructor(private _approvalService: ApprovalService) {
    //
  }

  //receive the id of the selected course
  public userCourseInfo: UserCoursesI = {} as UserCoursesI;
  @Input('selectedCourseInfo') set selectedCourseInfo(value: UserCoursesI) {
    if (!value || Object.keys(value).length === 0)
      //checks if @input is empty
      return;

    this.userCourseInfo = value;
    this.updateSubjectList();
  }

  @Input('isWithinDateRange') isWithinDateRange: boolean = false;
  @Input('userInfo') userInfo: UserI | undefined = undefined;
  @Input('selectedSubject') selectedSubject: string = 'all';
  public showApprovalModal: boolean = false;
  public selectedSubjectInfo: SubjectsI = {} as SubjectsI;
  public selectedUserSubjectInfo: UserSubjectI = {} as UserSubjectI;
  public data: any = {};
  public academicAreas: AcademicaAreaV2I[] = [];
  private _data: any = {};
  private _subscription: Subscription = new Subscription();
  public readonly SCHOOL_NAME: string = environment.SCHOOL_NAME;

  ngOnInit(): void {
    //
  }

  /**
   * updates the list of subjects of the selected course
   */
  public updateSubjectList() {
    Swal.fire({
      title: 'Cargando...',
      text: 'Esto no debería tardar mucho',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    Swal.showLoading();

    this.getSubjectGroups()
      .pipe(
        switchMap((subjectGroups) => {
          const subjectGroupIDs = subjectGroups.map((e) => e.id);
          return this.getUserSubjects(subjectGroupIDs).pipe(
            map((userSubjects) => ({ subjectGroups, userSubjects }))
          );
        })
      )
      .subscribe({
        next: ({ subjectGroups, userSubjects }) => {
          this.setData(userSubjects, subjectGroups);
          this.getAcademicAreas(userSubjects);
          Swal.close();
        },
        error: (err) => this.errorHandler(err),
      });
  }

  private setData(userSubjects: any, subjectGroups: any) {
    this._data = {
      pending: this.getPending(userSubjects, subjectGroups),
      approved: this.getApproved(userSubjects),
      studying: this.getStudying(userSubjects),
      unapproved: this.getUnapproved(userSubjects),
      certified: this.getCertified(userSubjects),
    };

    this.data = { ...this._data };
  }

  //Groups without userSubject relation
  private getPending(
    userSubjects: UserSubjectIV2[],
    groups: SubjectV2GroupOffer[]
  ) {
    const userSubjectIDs = new Set(
      userSubjects.map((userSubject) => userSubject.eduSup_SubjectIID)
    );
    return groups.filter((g) => !userSubjectIDs.has(g.subjectV2OfferID));
  }

  //All the subjects where is not IsHomolog yet
  private getStudying(userSubjects: UserSubjectIV2[]) {
    if (userSubjects.length === 0 || !this.isWithinDateRange) return [];

    const data = userSubjects.filter((userSubject: UserSubjectIV2) => {
      const hasUserRecords =
        userSubject.UserRecordsV2 &&
        userSubject.UserRecordsV2.length > 0 &&
        !userSubject.eduSup_IsHomolog;
      return hasUserRecords;
    });

    return this.groupUserSubjectsByArea(data);
  }

  private getApproved(userSubjects: UserSubjectIV2[]) {
    if (userSubjects.length === 0) return [];

    const data = userSubjects.filter(
      (userSubject: UserSubjectIV2) =>
        userSubject.UserRecordsV2?.every(
          (userRecord) => userRecord.isAccording
        ) &&
        !userSubject.eduSup_IsHomolog &&
        userSubject.isAproved
    );
    return this.groupUserSubjectsByArea(data);
  }

  private getUnapproved(userSubjects: UserSubjectIV2[]) {
    if (userSubjects.length === 0) return [];

    const data = userSubjects.filter(
      (userSubject: UserSubjectIV2) =>
        userSubject.UserRecordsV2?.every(
          (userRecord) => userRecord.isAccording
        ) &&
        !userSubject.eduSup_IsHomolog &&
        !userSubject.isAproved
    );
    return this.groupUserSubjectsByArea(data);
  }

  private getCertified(userSubjects: UserSubjectIV2[]) {
    if (userSubjects.length === 0) return [];

    const data = userSubjects.filter(
      (userSubject) => userSubject.eduSup_IsHomolog
    );

    return this.groupUserSubjectsByArea(data);
  }

    private groupUserSubjectsByArea(userSubjects: UserSubjectIV2[]) {
    const groupedData: any[] = [];
    const subjects = userSubjects.map((e) => e.SubjectV2GroupOffer.SubjectV2Offer);
    const areadIds = [
      ...new Set(subjects.map((subject) => subject.academicAreaID)),
    ];

    for (const areaId of areadIds) {
      const currentArea = subjects.find(
        (subject) => subject.academicAreaID === areaId
      );
      const areaData = {
        areaName: currentArea?.academicAreaID
          ? `Área ${currentArea.AcademicaArea.NameArea}`
          : 'Sin área',
        subjects: userSubjects.filter(
          (userSubject) =>
            userSubject.SubjectV2GroupOffer.SubjectV2Offer.academicAreaID === areaId
        ),
      };

      groupedData.push(areaData);
    }
    return groupedData;
  }

  /**
   * is executed each time a key is pressed.
   * @param event
   * @returns
   */
  public filterList(event: any) {
    const subjectName = event.subjectName.toLowerCase();
    const areaId = event.areaId;

    for (const key in this.data) {
      if (this.data.hasOwnProperty(key) && Array.isArray(this.data[key])) {
        this.data[key] = this._data[key].map((data: any) => ({
          ...data,
          subjects: data.subjects.filter((subject: any) => {
            const nameCondition =
              subjectName.length >= 4
                ? subject.SubjectV2GroupOffer?.SubjectV2Offer?.nameSubject
                    ?.toLowerCase()
                    ?.includes(subjectName)
                : true;

            const areaCondition =
              areaId !== 0
                ? subject.SubjectV2GroupOffer?.SubjectV2Offer?.AcademicaArea?.id === areaId
                : true;

            return nameCondition && areaCondition;
          }),
        }));
      }
    }
  }

  /**
   * gets all userSubjects of all subjects
   * @param subjects
   * @returns
   */
  private getUserSubjects(SubjectGroupIDs: number[]) {
    const filter = {
      where: {
        and: [
          { UserID: this.userCourseInfo.UserID },
          { SubjectGroupID: { inq: SubjectGroupIDs } },
        ],
      },
      include: [
        {
          SubjectV2GroupOffer: [
            {ProfessorToSubjectGroups: 'professor'},
            { SubjectV2Offer: 'AcademicaArea' },
          ],
        },
        { UserRecordsV2: ['MidTermV2'] },
      ],
    };

    return this._approvalService.getUserSubjectsV2(JSON.stringify(filter));
  }

  private getSubjectGroups() {
    const filter = {
      where: {
        and: [
          { CourseOferID: this.userCourseInfo.coursesOfers.id },
          { CourseGroupID: this.userCourseInfo.CourseGroupID },
        ],
      },
      include: ['SubjectV2Offer'],
    };

    return this._approvalService.getSubjectGroupsV2(JSON.stringify(filter));
  }

  private getAcademicAreas(userSubjects: UserSubjectIV2[]) {
    const areas = userSubjects.map(
      (e) => e.SubjectV2GroupOffer.SubjectV2Offer.AcademicaArea
    );
    this.academicAreas = areas
      .filter((e) => e !== undefined)
      .filter(
        (e, index) => areas.findIndex((obj) => obj.id === e.id) === index
      );
  }

  public deleteSubject() {
    // if (![1, 3, 18].some((e) => this._approvalService.roles.includes(e)))
    //   return;
    // Swal.fire({
    //   icon: 'warning',
    //   title: 'Estas seguro?',
    //   text: `Estas a punto de eliminar  ${this.selectedSubjectInfo.NameSubject}`,
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     Swal.fire({
    //       title: 'Eliminación en proceso',
    //       text: 'Por favor, espera un momento.',
    //       allowEscapeKey: false,
    //       allowOutsideClick: false,
    //     });
    //     Swal.showLoading();
    //     const selectedSubjectId = this.selectedUserSubjectInfo.id;
    //     this._approvalService.deleteUserSubject(selectedSubjectId).subscribe({
    //       complete: () => {
    //         Swal.fire({
    //           icon: 'success',
    //           title: 'Hecho',
    //           text: 'Se ha eliminado la materia',
    //           allowEscapeKey: false,
    //           allowOutsideClick: false,
    //         }).then((result) => {
    //           if (result.isConfirmed) this.updateSubjectList();
    //         });
    //       },
    //       error: (error) => {
    //         Swal.close();
    //         console.log(error);
    //       },
    //     });
    //   }
    // });
  }

  public getHeaderNames(): string[] {
    const headerNames = ['Materia', 'Inasistencias', 'Nota', 'Habilitar Nota'];
    if (this.selectedSubject === 'all') headerNames.push('Estado');

    return headerNames;
  }

  private errorHandler(err) {
    Swal.fire({
      icon: 'error',
      text:
        err.status === 401
          ? 'Error de permisos.'
          : 'Error al procesar su solicitud.',
    });
    console.error(err);
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}

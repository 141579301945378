import { Routes } from '@angular/router';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { ManageProfessorComponent } from './components/manage-professor/manage-professor.component';
import { msDataResolver } from '../resolvers/ms-data-resolver.resolver';
import { AssignProfessorComponent } from './components/assign-professor/assign-professor.component';
import { PerformanceManagementComponent } from './pages/performance-management/performance-management.component';
import { TimetableComponent } from './components/timetable/timetable.component';
import { RecordsManagementComponent } from './pages/records-management/records-management.component';

export const routes: Routes = [
  { path: 'manage-professor',
    resolve: { msData: msDataResolver },
    children:[
      { path: 'manageUsers', component: ManageProfessorComponent },
      { path: 'assignUsers', component: AssignProfessorComponent },
      { path: 'performance-management', component: PerformanceManagementComponent },
      { path: 'records-management', component: RecordsManagementComponent },
      { path: 'timetable', component: TimetableComponent, },
    ] 
  },
  { path: '**', component: EmptyRouteComponent },
];

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UiDateInputComponent } from '@components/ui/ui-date-input/ui-date-input.component';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import { UiSelectContentComponent } from '@components/ui/ui-select/ui-select-content/ui-select-content.component';
import { UiSelectItemComponent } from '@components/ui/ui-select/ui-select-item/ui-select-item.component';
import { UiSelectComponent } from '@components/ui/ui-select/ui-select.component';
import { AcademicaAreaOffer } from '@models/db/AcademicaAreaOffer';
import { AcademicPerformanceOffer } from '@models/db/AcademicPerformanceOffer';
import { Category } from '@models/db/category';
import { Course } from '@models/db/course';
import { CourseOffer } from '@models/db/courseOffer';
import { CourseOfferInfoMidterms } from '@models/db/CourseOfferInfoMidterms';
import { Modality } from '@models/db/Modality';
import { SubjectV2Offers } from '@models/db/SubjectV2Offers';
import { TypeCourse } from '@models/db/typeCourse';
import { TypeTraining } from '@models/db/TypeTraining';
import * as ALL_ENUMS from '@models/logicModels/mallas/enumsMallas';
import { CourseInfoMidTermProperties, ManagementAreaOfferToShow, ManagementSubjectAreaOfferToShow, ManageOfferModalDataToShow, ManageTabsOfferModalProperties } from '@models/logicModels/mallas/modalsData';
import { ApiService } from '@services/api-service.service';
import { SweetalertService } from '@services/sweetalert.service';
import { concat, forkJoin, map, Observable, of, switchMap, take, toArray } from 'rxjs';

@Component({
  selector: 'modal-manage-offer',
  standalone: true,
  imports: [
    /** Jhan components */
    UiSelectComponent,
    UiSelectContentComponent,
    UiSelectItemComponent,
    UiInputComponent,
    UiDateInputComponent,
    /** Angular */
    FormsModule,
    CommonModule,
  ],
  templateUrl: './manage-offer.component.html',
  styleUrl: './manage-offer.component.css'
})
export class ManageOfferComponent {

  @Input() isNewData!: boolean;
  @Input() offerID!: number;
  @Input() offerData!: CourseOffer;
  @Input() courseData!: Course;
  @Output() eventCloseModal = new EventEmitter<void>();
  @Output() eventUpdateDataAndCloseModal = new EventEmitter<void>();

  protected localOfferData: CourseOffer = new CourseOffer();
  protected AllLocalInfoMidTerms: CourseOfferInfoMidterms[] = [];
  protected dataToShow: ManageOfferModalDataToShow = {
    tittle: '',
    subTittle: '',
    btnText: '',
  };
  protected MANAGE_TABS: ManageTabsOfferModalProperties = {
    allOptions: ALL_ENUMS.enumOfferModalTabsOptions,
    index: ALL_ENUMS.enumOfferModalTabsOptions.DETAILS,
  };
  protected areaManagementToShow: ManagementAreaOfferToShow[] = [];
  protected ALL_CATEGORIES: Category[] = [];
  protected ALL_TYPE_COURSES: TypeCourse[] = [];
  protected ALL_TYPE_TRAINING: TypeTraining[] = [];
  protected ALL_MODALITIES: Modality[] = [];
  protected localCourseData_categoryID: number = 0; /** Revisar esta variable */

  constructor (
    private _apiService: ApiService,
    private _sweetalertService: SweetalertService,
  ) {
    this.ALL_CATEGORIES = this._apiService.ALL_CATEGORIES_LIST();
    this.ALL_TYPE_COURSES = this._apiService.ALL_TYPE_COURSES();
    this.ALL_TYPE_TRAINING = this._apiService.ALL_TYPE_TRAINING();
    this.ALL_MODALITIES = this._apiService.ALL_MODALITIES();
  };

  ngOnInit(): void {
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    this.dataToShow = {
      tittle: ( this.isNewData ? 'Crear nueva oferta' : 'Editar oferta' ),
      subTittle: ( this.isNewData ? 'Agrega' : 'Edita' ),
      btnText: ( this.isNewData ? 'Crear' : 'Actualizar' ),
    };

    /** TODO: Revisar logica para crear y editar */
    this.areaManagementToShow = []; // Aquí se inicializa la estructura para saber que area y materias se guardan
    this.courseData?.AcademicAreasCourse?.forEach(areaIterated => {
      let tempAreaBool = ( this.isNewData ? false : this.getConfirmAreaSelectedByAreaID( areaIterated.id! ) );
      let dataArea: ManagementAreaOfferToShow = {
        isSelected: tempAreaBool,
        isSavedOnDB: tempAreaBool,
        numberOfSubjectsSelected: 0,
        name: areaIterated.AcademicArea?.NameArea!,
        idArea: areaIterated.id!,
        academicAreaData: areaIterated,
        allSubjectList: [],
      };
      areaIterated.SubjectsV2?.forEach(subjectIterated => {
        let tempSubjectBool = ( this.isNewData ? false : this.getConfirmSubjectSelectedBySubjectID( subjectIterated.id! ) );
        let dataSubject: ManagementSubjectAreaOfferToShow = {
          isSelected: tempSubjectBool,
          isSavedOnDB: tempSubjectBool,
          name: `${subjectIterated.nameSubject!}`,
          idSubject: subjectIterated.id!,
          subjectData: subjectIterated,
          academicPerformances: [],
        };
        if (dataSubject.subjectData.isGradeByTask) {
          let academicPerformanceData: AcademicPerformanceOffer = {
            originAcademicPerformanceID: 0,
            subjectV2OfferID: 0,
            descripionPerformance: '',
            performanceNum: 1,
            percentage: 100,
            midTermV2ID: 1,
            performanceName: '',
            tasksNumber: 0,
          };
          if (tempSubjectBool) dataSubject.academicPerformances = this.getAcademicPerformanceToEdit(subjectIterated.id!);
          else dataSubject.academicPerformances.push(academicPerformanceData);
        }
        dataArea.allSubjectList.push(dataSubject);
        /** TODO: Revisar logica para desempeños */
      });

      this.areaManagementToShow.push(dataArea);
    });

    if (this.isNewData) this.setDefaulValuesOnCreate();
    else if (this.offerData) {
      this.localOfferData = this.offerData;
      this.AllLocalInfoMidTerms = JSON.parse(JSON.stringify(this.offerData.infoMidterms));
      this.areaManagementToShow.forEach(areaElement => {
        areaElement.allSubjectList.forEach(subjectElement => {

          const exists = this.offerData?.SubjectV2Offers?.find( subjetOffer => subjetOffer.originSubjectV2CourseID == subjectElement.subjectData.id );
          if (exists) {
            subjectElement.isSavedOnDB = true;
            subjectElement.isSelected = true;
            areaElement.isSavedOnDB = true;
            areaElement.isSelected = true;
            if ( exists.AcademicPerformancesOffer && exists.AcademicPerformancesOffer.length > 0 ) subjectElement.academicPerformances = exists.AcademicPerformancesOffer;
          }

        });
      });
    } else {
      this._sweetalertService.swalError('Error', 'No se pudo cargar la información correctamente, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{ this.closeModal() });
      return;
    }
    this._sweetalertService.swalClose();
  }

  setDefaulValuesOnCreate(): void {
    console.log('*************************************************************************');
    console.log('*************************************************************************');
    console.log('*************************************************************************');
    console.log('setDefaulValuesOnCreate');
    this.localOfferData = new CourseOffer();
    this.localOfferData.version = 2;
    this.localOfferData.SchoolID = this.courseData.SchoolID;
    this.localOfferData.CourseID = this.courseData.id;

    
    // this.localOfferData.Description = // this.courseData.Description: "pruebas david edit003"
    // this.localOfferData.DesiredGrade = // this.courseData.DesiredGrade: 7
    // this.localOfferData.DurationTotal = // this.courseData.DurationTotal: null
    this.localOfferData.IsActive = this.courseData.IsActive;
    // this.localOfferData.IsAnnual = // this.courseData.IsAnnual: false
    // this.localOfferData.IsBase = // this.courseData.IsBase: false
    // this.localOfferData.IsMilitar = // this.courseData.IsMilitar: false
    this.localOfferData.IsSemestralize = this.courseData.IsSemestralize;
    this.localOfferData.NameCourseOfer = `Oferta ${this.courseData.NameCourse}`;
    // this.localOfferData.NoRegisterMiL = // this.courseData.NoRegisterMiL: null
    // this.localOfferData.NoRegisterMinE = // this.courseData.NoRegisterMinE: null
    // this.localOfferData.TeachingHoursmin = // this.courseData.TeachingHoursmin: 45
    this.localCourseData_categoryID = this.courseData.TypeCourse?.CategoryID!;
    this.localOfferData.TypeCourseID = this.courseData.TypeCourseID;
    // this.localOfferData.TypeRecord = // this.courseData.TypeRecord: "1-10"
    this.localOfferData.createdBy = this._apiService.userInfo()!.id!;
    // this.localOfferData.durationSemester = // this.courseData.durationSemester: null
    this.localOfferData.Forces = this.courseData.forces;
    this.localOfferData.Profiles = ''+this.courseData.profiles;
    // this.localOfferData.requirements = // this.courseData.requirements: null
    // this.localOfferData.totalSemester = // this.courseData.totalSemester: null
    // this.localOfferData.typeCourseDurationID = // this.courseData.typeCourseDurationID: 2
    
    let tempMidTerm: CourseInfoMidTermProperties[] = JSON.parse(JSON.stringify(this.courseData.infoMidterms));
    tempMidTerm.forEach(midTermIterated => {
      this.AllLocalInfoMidTerms.push( new CourseOfferInfoMidterms(midTermIterated) );
    });


    console.log('localOfferData', this.localOfferData);
    console.log('courseData', this.courseData);
    console.log('areaManagementToShow', this.areaManagementToShow);
  }

  getConfirmAreaSelectedByAreaID( areaID: number): boolean {
    let index = this.localOfferData?.SubjectV2Offers?.findIndex( x => x.academicAreaID == areaID );
    return (index != undefined && index >= 0);
  }

  getConfirmSubjectSelectedBySubjectID( subjectID: number): boolean {
    // const exists = this.localOfferData?.AcademicAreasCourse?.some(area => 
    //   area.SubjectsV2?.some(subject => subject.subjectV2ID === subjectID)
    // );
    // return exists ?? false;
    let index = this.localOfferData?.SubjectV2Offers?.findIndex( x => x.originSubjectV2CourseID == subjectID );
    return (index != undefined && index >= 0);
  }

  getAcademicPerformanceToEdit( idSubjectToFind: number ): AcademicPerformanceOffer[] {

    let foundSubject: SubjectV2Offers | undefined = this.localOfferData?.SubjectV2Offers?.find( x => x.originSubjectV2CourseID == idSubjectToFind );
    let dataString: string = '[]';
    if (foundSubject != undefined) dataString = JSON.stringify(foundSubject.AcademicPerformancesOffer);
    const dataArray = JSON.parse(dataString);
    const academicPerformanceList: AcademicPerformanceOffer[] = dataArray.map((item: any) => {
      return Object.assign(new AcademicPerformanceOffer(), item);
    });
    return academicPerformanceList;
    // let foundSubject: SubjectV2Offers | null = null;
    // this.localCourseData?.AcademicAreasCourse?.some(area => {
    //   return area.SubjectsV2?.some(subject => {
    //     if (subject.subjectV2ID === idSubjectToFind) {
    //       foundSubject = subject; // Se guarda la materia encontrada
    //       return true; // Se detiene la búsqueda en este punto
    //     }
    //     return false;
    //   });
    // });
    // let dataString: string = '[]';
    // if (foundSubject != null) dataString = JSON.stringify(foundSubject['AcademicPerformances']);
    // const dataArray = JSON.parse(dataString);
    // const academicPerformanceList: AcademicPerformance[] = dataArray.map((item: any) => {
    //   return Object.assign(new AcademicPerformance(), item);
    // });
    // return academicPerformanceList;
  }

  /* ****************
   * OUTPUT FUNCTIONS
   * **************** */
  protected closeModal(): void {
    this.eventCloseModal.emit();
  }

  protected updateDataAndCloseModal(): void {
    this.eventUpdateDataAndCloseModal.emit();
  }

  /* *******************
   * HTML BTNS FUNCTIONS
   * ******************* */
  protected confirmProcessToValidateData(): void {
    this._sweetalertService.swalQuestion(
      'Confirmar acción',
      `¿Esta seguro de guardar esta oferta?`,
      () => {
        this.validateDataBeforeSendingReq()
      }
    );
  }

  protected validateDataBeforeSendingReq(): void {
    console.log('validateDataBeforeSendingReq');
    console.log('localOfferData: ', this.localOfferData);
    let isOk = true;
    if (this.isNewData) {
      this.areaManagementToShow.forEach(areaIterated => {
        areaIterated.numberOfSubjectsSelected = 0;
        areaIterated.allSubjectList.forEach(subjectIterated => {
          if (subjectIterated.isSelected) areaIterated.numberOfSubjectsSelected++;
        });
      });
      /** TODO: hacer validaciones de campos */
      if (isOk) this.createNewOffer();
      else this._sweetalertService.swalError('Error', 'Datos incompletos', ()=>{});
    } else {
      /** TODO: hacer validaciones de campos */
      /** TODO: hacer validaciones de si hay campos editados */
      if (isOk) this.editOfferSelected();
    }
  }

  protected confirmDeleteNotesSectionSelected(indexNoteClicked: number): void {
    if (this.AllLocalInfoMidTerms.length <= 1) return;
    this.AllLocalInfoMidTerms.splice(indexNoteClicked, 1);
    this.AllLocalInfoMidTerms.forEach((element, index) => {
      element.midtermNum = (index + 1);
    });
  }

  protected addNewNoteSection(): void {
    console.log('addNewAcademicPerformanceSection');
    let academicPerformanceData: CourseOfferInfoMidterms = {
      midtermNum: this.AllLocalInfoMidTerms.length+1,
      description: '',
      percentage: 100,
      professorCanEditPercentage: false,
      midTermStart: '',
      midTermEnd: '',
      midTermRecordStart: '',
      midTermRecordEnd: '',
      remedialGradeStart: '',
      remedialGradeEnd: '',
    };
    this.AllLocalInfoMidTerms.push(academicPerformanceData);
  }

  protected confirmDeleteAcademicPerformanceSectionSelected(subjectIterated: ManagementSubjectAreaOfferToShow, indexNoteClicked: number): void {
    if (subjectIterated.academicPerformances.length <= 1) return;
    subjectIterated.academicPerformances.splice(indexNoteClicked, 1);
    subjectIterated.academicPerformances.forEach((element, index) => {
      element.performanceNum = (index + 1);
    });
  }

  protected addNewAcademicPerformanceSection(subjectIterated: ManagementSubjectAreaOfferToShow, midtermNumReceived: number): void {
    let actualPerformancesOfThisMidterm = subjectIterated.academicPerformances.filter( x=> x.midTermV2ID == midtermNumReceived );
    let academicPerformanceData: AcademicPerformanceOffer = {
      originAcademicPerformanceID: 0,
      subjectV2OfferID: 0,
      descripionPerformance: '',
      performanceNum: (actualPerformancesOfThisMidterm.length + 1),
      percentage: 100,
      midTermV2ID: midtermNumReceived,
      performanceName: '',
      tasksNumber: (actualPerformancesOfThisMidterm.length + 1),
    };
    subjectIterated.academicPerformances.push(academicPerformanceData);
  }

  /* ************************
   * FLUJO CREAR NUEVA OFERTA
   * ************************ */
  protected createNewOffer(): void {
    /** CoursesOfer -> AcademicaAreaOffer -> SubjectV2Offer -> AcademicPerformanceOffer */
    console.log('createNewOffer');
    this.localOfferData.infoMidterms = JSON.stringify(this.AllLocalInfoMidTerms);
    this.localOfferData.createdBy = this._apiService.userInfo()!.id!;
    const dataToCreateOffer = {
      path: `CoursesOfers`,
      data: this.localOfferData
    };
    console.log('localOfferData', this.localOfferData);
    console.log('AllLocalInfoMidTerms', this.AllLocalInfoMidTerms);
    console.log('areaManagementToShow', this.areaManagementToShow);
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin({
      NEW_OFFER_00: this._apiService.post( dataToCreateOffer ).pipe(
        take( 1 ),
        switchMap( responseOffer => this.createAreasAfterOfferCreated(responseOffer) ),
      ),
    }).subscribe({
      next: (responseCreate) => {
        console.log('responseCreate: ', responseCreate);
        this._sweetalertService.swalSuccess('Ok', '¡Los datos se han guardado exitosamente!', ()=>{ this.updateDataAndCloseModal() });
      },
      error: (err) => {
        console.log("createNewOffer error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo guardar la información, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      }
    });
  }

  private createAreasAfterOfferCreated(responseOffer: CourseOffer): Observable<any> {
    let areaObserbable: any = [];
    this.areaManagementToShow.forEach(areaIterated => {
      if (areaIterated.numberOfSubjectsSelected == 0) return;
      let dataArea: AcademicaAreaOffer = {
        year: 2025, /** TODO: Obtener bien este dato */
        academicAreaID: areaIterated.idArea,
        courseOfferID: responseOffer.id,
        taskNumber: 0,
      };
      let dataReq = {
        path: "/AcademicaAreaOffers",
        data: dataArea
      };
      areaObserbable.push( this._apiService.post<AcademicaAreaOffer>( dataReq ).pipe(
        take(1),
        switchMap( responseArea => this.createSubjectsV2OfferAfterAreaCreated(responseArea, areaIterated) ),
      ) );
    });
    if (areaObserbable.length == 0) return of([]);
    return concat(...areaObserbable).pipe(
      toArray(),
      map((responseAcadAreaOffer)=>({responseOffer, responseAcadAreaOffer}))
    );
  }

  private createSubjectsV2OfferAfterAreaCreated(responseArea: AcademicaAreaOffer, areaIterated: ManagementAreaOfferToShow): Observable<any> {
    let subjectAreaObserbable: any = [];
    areaIterated.allSubjectList.forEach(subjectIterated => {
      if (!subjectIterated.isSelected) return; // Si no esta seleccionada la materia, la quito
      let dataSubject: SubjectV2Offers = this.getNewSubjectV2OfferData(subjectIterated, responseArea.id!, responseArea.courseOfferID!);
      let dataReqSubj = { path: "/SubjectV2Offers", data: dataSubject };
      subjectAreaObserbable.push( this._apiService.post<SubjectV2Offers>( dataReqSubj ).pipe(
        take(1),
        switchMap(responseSubject => this.createAcademicPerformanceOfferAfterSubjectV2OfferCreated(responseSubject, subjectIterated) ),
      ) );
    });
    if (subjectAreaObserbable.length == 0) return of([]);
    return concat(...subjectAreaObserbable).pipe(
      toArray(),
      map((responseAreaSubject)=>({responseArea, responseAreaSubject}))
    );
  }

  private createAcademicPerformanceOfferAfterSubjectV2OfferCreated(responseSubject: SubjectV2Offers, subjectIterated: ManagementSubjectAreaOfferToShow): Observable<any> {
    let academicPerformanceObserbable: any = [];
    subjectIterated.academicPerformances.forEach(desempenhoIterated => {
      desempenhoIterated.subjectV2OfferID = responseSubject.id;
      desempenhoIterated.originAcademicPerformanceID = desempenhoIterated.id;
      
      desempenhoIterated.performanceName = desempenhoIterated.descripionPerformance;
      desempenhoIterated.tasksNumber = desempenhoIterated.performanceNum;

      let dataAcademicPerf = {
        path: "/AcademicPerformancesOffer",
        data: desempenhoIterated
      };
      academicPerformanceObserbable.push( this._apiService.post( dataAcademicPerf ).pipe( take(1) ) );
    });
    if (academicPerformanceObserbable.length == 0) return of([]);
    return concat(...academicPerformanceObserbable).pipe(
      toArray(),
      map((responseAcademicPerformanceOffer)=>({responseSubject, responseAcademicPerformanceOffer}))
    );
  }

  /** TODO: descript update */
  private getNewSubjectV2OfferData(subjectIterated: ManagementSubjectAreaOfferToShow, areaID: number, courseOfferID: number): SubjectV2Offers {
    return {
      nameSubject: subjectIterated.name,
      courseOfferID: courseOfferID,
      // subjectV2ID_Requirement: subjectIterated.subjectData.SubjectID_Requirement, /** TODO: LUIS NO ESTA EN EL MODELO DE BACKV2 */
      HPM: subjectIterated.subjectData.HPM,
      HFH: subjectIterated.subjectData.HFH,
      HTI: subjectIterated.subjectData.HTI,
      description: subjectIterated.subjectData.description,
      eduSup_semesterNumber: subjectIterated.subjectData.eduSup_semesterNumber,
      maxSlotsPerDay: subjectIterated.subjectData.maxSlots,
      creditNumber: subjectIterated.subjectData.creditos,
      typeRecord: subjectIterated.subjectData.typeRecord,
      desiredGrade: subjectIterated.subjectData.desiredGrade,
      typeCreditID: subjectIterated.subjectData.typeCreditID,
      isGradeByTask: subjectIterated.subjectData.isGradeByTask,
      originSubjectV2CourseID: subjectIterated.subjectData.id,
      academicAreaID: areaID,
    };
  }

  /* *******************
   * FLUJO EDITAR OFERTA
   * ******************* */
  protected editOfferSelected(): void {
    console.log('editOfferSelected');
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    const dataToUpdate = {
      path: `CoursesOfers/${this.offerID}`,
      data: this.localOfferData
    };
    this._apiService.patch( dataToUpdate ).pipe( take(1) ).subscribe({
      next: (responseUpdate) => {
        console.log('responseUpdate: ', responseUpdate);
        this._sweetalertService.swalSuccess('Ok', '¡Los datos se han actualizado exitosamente!', ()=>{ this.updateDataAndCloseModal() });
      },
      error: (err) => {
        console.log("editAreaSelected error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo guardar la información, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      }
    });
  }

}

<div class="containerAssignProfesor">
  <div class="filterSection">
    <div class="filterSection_item filterSection_item--width">
      <ui-select [variant]="'bordered'" [label]="textsToShow.schoolFilterTittle" defaultValue="0" (onValueChange)="onSchoolSelectChange($event)">
        <ui-select-content>
          <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
          @for (schoolIterated of filtersData.selectOptions.schools; track schoolIterated.id) {
            <ui-select-item [value]="schoolIterated.id!">{{ schoolIterated.NameTSchool }}</ui-select-item>
          }
        </ui-select-content>
      </ui-select>
    </div>
    <div class="filterSection_item--width"></div>
  </div>
  <div class="contentSection">
    @if (filtersData.dataSelected.showSchool) {
      <div class="contentSection_localFilters">
        <div class="localFilters_item">
          <ui-select [label]="'Profesor'" defaultValue="0" (onValueChange)="onProfesorSelectChange($event)">
            <ui-select-content>
              <ui-select-item value="0" [disabled]="true">Seleccionar</ui-select-item>
              @for (contract of MANAGE_SELECTS_DATA.USER_JOB_CONTRACT; track contract.id) {
                <ui-select-item [value]="contract.id!">{{ contract.Userapps?.LastName1 }} {{ contract.Userapps?.Name1 }}</ui-select-item>
              }
            </ui-select-content>
          </ui-select>
        </div>
      </div>
    }
    @if (MANAGE_SELECTS_DATA.showUserData) {
      <div class="contentSection_userData">
        @for (contrato of MANAGE_SELECTS_DATA.USER_JOB_CONTRACT; track contrato.id) {
          <div>
            <strong>Contrato:</strong> {{ contrato.ContractNumber }} - {{ contrato.TypeContracts?.NameTypeContract }} <br>
            <strong>Duración:</strong> {{ contrato.StartContract }} - {{ contrato.EndContract }} <br>
            <!-- <strong>Horas:</strong> {{ contrato.ContractHours }} <br> -->
            <strong>Activo:</strong> {{ contrato.isActive ? 'Si' : 'No' }}
          </div>
        }
      </div>

      <!-- asd
      HORAS:  -->
      <div class="contentSection_calendar">

        <div class="col-md-4">
          <div class="btn-group">
            <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay()">
              <i class="fa fa-angle-left"></i>
            </div>
            <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
              Hoy
            </div>
            <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay()">
              <i class="fa fa-angle-right"></i>
            </div>
          </div>
        </div>

        @if (showCalendar) {
          <mwl-calendar-week-view
            [viewDate]="viewDate"
            [events]="events"
            [hourSegments]="4"
            [dayStartHour]="6"
            [dayEndHour]="18"
            [excludeDays]="[0]"
            (eventClicked)="handleEvent($event.event)">
          </mwl-calendar-week-view>
        }
      </div>
    }
  </div>
</div>

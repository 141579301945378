import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { MenuLeftComponent } from './components/menu-left/menu-left.component';
import { MenuLeftComponentCelic } from './components/menu-left-celic/menu-left-celic.component';

import { ProfileNavComponent } from './components/profile-nav/profile-nav.component';


import { UsersService } from './models/usersInfo/users.service';
import { CoursesService } from './models/Coursesinfo/courses.service';
import { AuthService } from "./models/Auth/auth.service";
import { AttachmentService } from "./models/attachment/attachment.service";

import { ChatService } from './models/chat/chat.service';
import { ChatboxComponent } from './components/top-bar/chatbox/chatbox.component';

import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OrderModule } from 'ngx-order-pipe';
import { ChartsModule } from 'ng2-charts';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app.routing';
import { PagesRegister } from './public/signup/signup.page';
import { Pages404 } from './public/404page/page-404';
import { CommonModule } from '@angular/common';





@NgModule({
  declarations: [
    EmptyRouteComponent,
    AppComponent,
    TopBarComponent,
    MenuLeftComponent,
    MenuLeftComponentCelic,
    ProfileNavComponent,
    ChatboxComponent,
    PagesRegister,
    Pages404,
  ],
  imports: [
    RecaptchaModule,
    RecaptchaFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    
    OrderModule,
    ChartsModule,
    CommonModule
  ],
  providers: [
    UsersService,
    CoursesService,
    AuthService,
    AttachmentService,
    ChatService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule {}

import { Component, computed, Input, OnInit, signal } from '@angular/core';
import { UiDropdownComponent } from "../../../components/ui/ui-dropdown/ui-dropdown.component";
import { UiDropdownTriggerComponent } from "../../../components/ui/ui-dropdown/ui-dropdown-trigger/ui-dropdown-trigger.component";
import { UiInputComponent } from "../../../components/ui/ui-input/ui-input.component";
import { UiDropdownContentComponent } from "../../../components/ui/ui-dropdown/ui-dropdown-content/ui-dropdown-content.component";
import { UiDropdownItemComponent } from "../../../components/ui/ui-dropdown/ui-dropdown-item/ui-dropdown-item.component";
import { UiBadgeComponent } from "../../../components/ui/ui-badge/ui-badge.component";
import { FormsModule } from '@angular/forms';
import { SubjectGroup } from '../../../models/db/SubjectGroup';
import { UserJobsContract } from '../../../models/db/UserJobsContract';
import { ApiService } from '../../../services/api-service.service';
import { SweetalertService } from '../../../services/sweetalert.service';
import { forkJoin, take } from 'rxjs';
import { IEndpoint } from '../../../models/endpoint';

@Component({
  selector: 'AP-subject-list',
  standalone: true,
  imports: [
    /** Jhan components */
    UiDropdownComponent,
    UiDropdownTriggerComponent,
    UiInputComponent,
    UiDropdownContentComponent,
    UiDropdownItemComponent,
    UiBadgeComponent,
    /** Angular components */
    FormsModule,
  ],
  templateUrl: './subject-list.component.html',
  styleUrl: './subject-list.component.css'
})
export class SubjectListComponent implements OnInit {
  
  @Input() subjectGroup!: SubjectGroup;
  @Input() profesorList!: UserJobsContract[];
  
  protected showProfesorSelect: boolean = false;

  private _multiselectItems: MulstiselectItem[] = [];
  public multiselectItemsChecked = signal<MulstiselectItem[]>([]);
  public multiselectInputValue = signal<string>('');

  constructor (
    readonly _apiService: ApiService,
    readonly _sweetalertService: SweetalertService,
  ) { };

  ngOnInit(): void {
    console.log('profesorList', this.profesorList);
    this._multiselectItems = [];
    this.profesorList.forEach(contratIterated => {
      let data: MulstiselectItem = {
        id: contratIterated.UserID!,
        name: `${contratIterated.Userapps?.Name1} ${contratIterated.Userapps?.LastName1}`
      }
      this._multiselectItems.push(data);
    });
  }

  protected saveProfesorList(): void {
    console.log('this.multiselectItemsChecked()', this.multiselectItemsChecked());
    let allDataToPost: any[] = [];
    this.multiselectItemsChecked().forEach(userSelectedIterated => {
      const dataToManageProfesors: IEndpoint = {
        path: "/ProfessorToSubjectGroups",
        data: {
          userID: userSelectedIterated.id,
          subjectGroupID: this.subjectGroup.id,
          subjectV2GroupOfferID: this.subjectGroup.CourseOferID,
        }
      };
      allDataToPost.push( this._apiService.post( dataToManageProfesors ).pipe( take(1) ) );
    });
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin( allDataToPost ).subscribe({
      next: (forkjoinResponse) => {
        console.log("saveProfesorList response:", forkjoinResponse);
        this._sweetalertService.swalClose();
      },
      error: (err) => {
        console.log("saveProfesorList error:", err);
        this._sweetalertService.swalError('Error', 'intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      },
      complete: () => {
        console.log("saveProfesorList complete:");
      }
    });
  }

  public removeBadge(id:number){
    const multiselectItemsChecked = this.multiselectItemsChecked();
    this.multiselectItemsChecked.set(multiselectItemsChecked.filter(e => e.id !== id));
  }

  public onSelectMultiselect(event: { value: string; label: string }) {
    this.multiselectItemsChecked.update(prev => [...prev, { id: Number(event.value), name: event.label }])
  }

  public multiselectItems = computed(() => {
    const inputValue = this.multiselectInputValue().toLowerCase();
    return this._multiselectItems
      .filter(item => inputValue.length === 0 || item.name.toString().toLowerCase().includes(inputValue))
      .map(item => ({
        ...item,
        disabled: this.multiselectItemsChecked().some(checked => checked.id === item.id)
      }));
  });

  showData(): void {
    console.log('subjectGroup', this.subjectGroup);
    console.log('profesorList', this.profesorList);
  }

}

interface MulstiselectItem {
  id: number;
  name: string;
  disabled?:false;
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule }  from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CustomFormsModule } from 'ng2-validation';
import { ValidationStaff } from './ValidationsOAP/validations.page';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ProfessorCoursesModule } from './professor/courses/professor-courses.module';
import { ProfessorCoursesEditModule } from './professor/edit-groupcourses/professor-edit-courses.module';
import { ClassRooms, ButtonViewTypeClassRoomComponent } from './ClassRooms/classrooms.page';
import { OrderModule } from 'ngx-order-pipe';
import { ProfessorCoursesV2Module } from './professor/coursesV2/professor-courses-v2.module';


export const routes: Routes = [
  { path: 'agrupacion/validationStaff', component: ValidationStaff },
  { path: 'agrupacion/classrooms', component: ClassRooms }
];

@NgModule({
  imports: [
    CommonModule,
    CustomFormsModule,
    FormsModule,
    ProfessorCoursesEditModule,
    ProfessorCoursesModule,
    ProfessorCoursesV2Module,
    Ng2SmartTableModule,
    OrderModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
  entryComponents: [
    ButtonViewTypeClassRoomComponent
  ],
  declarations: [
    ButtonViewTypeClassRoomComponent,
    ValidationStaff,
    ClassRooms
  ]
})
export class modAgrupacion { }
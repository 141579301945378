import { Component, OnInit } from '@angular/core';
import { UiSelectComponent } from "../ui/ui-select/ui-select.component";
import { UiSelectContentComponent } from "../ui/ui-select/ui-select-content/ui-select-content.component";
import { UiSelectItemComponent } from "../ui/ui-select/ui-select-item/ui-select-item.component";
import { ApiService } from '@services/api-service.service';
import { SweetalertService } from '@services/sweetalert.service';
import { environment } from 'src/environments/environment';
import { School } from '@models/db/school';
import { UserJobsContract } from '@models/db/UserJobsContract';
import { IDropdownItem } from '@models/ui/dropdown-item';
import { FormsModule } from '@angular/forms';
import { forkJoin, map, switchMap, take } from 'rxjs';
import { CalendarEvent, CalendarModule, CalendarView } from 'angular-calendar';
import { IUserUserApp } from '@models/users';
import { ScheduleSubject } from '@models/db/ScheduleSubject';
import moment from 'moment';


@Component({
  selector: 'app-timetable',
  standalone: true,
  imports: [
    /** Jhan components */
    UiSelectComponent,
    UiSelectContentComponent,
    UiSelectItemComponent,
    /** Angular components */
    FormsModule,
    /** Calendar */
    CalendarModule,
  ],
  templateUrl: './timetable.component.html',
  styleUrl: './timetable.component.css'
})
export class TimetableComponent implements OnInit {

  protected textsToShow = {
    schoolFilterTittle: 'Filtro por __',
  };

  protected filtersData: FiltersData = {
    selectOptions: {
      schools: this._apiService.schools(),
    },
    dataSelected: {
      school: -1,
      showSchool: false,
    }
  };

  protected MANAGE_SELECTS_DATA: ManageSelectDataInt = {
    USER_JOB_CONTRACT: [],
    userIdSelected: 0,
    showUserData: false
  };

  constructor (
    readonly _apiService: ApiService,
    readonly _sweetalertService: SweetalertService,
  ) {
    this.textsToShow = {
      schoolFilterTittle: `Filtro por ${environment.APP_DATA.SCHOOL_TEXT.toLowerCase()}`
    };
  }

  ngOnInit(): void {
    this.getAllInitialData();
  }

  protected getAllInitialData(): void {
    console.log('getAllInitialData');
    const userId = localStorage.getItem('currentUser');
    if (!userId) {
      console.error(`No "current user" field found in local storage`);
      return;
    }
    const userParams = {
      path: 'Userapps',
      filter: {
        where: { id: userId },
        include: [{ roleMappings: ['school'] }],
      },
    };

    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    this._apiService.get<IUserUserApp>(userParams).pipe(
      take(1),
      map((users) => users[0]),
      switchMap((userInfo) => {
        const { roleMappings } = userInfo;
        const schoolIDs = roleMappings.map(item => item.SchoolID);
        const schoolParams = {
          path: 'Schools',
          filter: { where: {
            or: [
              { id: {inq: schoolIDs} },
              { DepenSchoolID: {inq: schoolIDs} }
            ]
          }}
        };
        return this._apiService.get<School>(schoolParams).pipe( take(1) );
      })
    ).subscribe({
      next: (dataResponse) => {
        console.log("getAllInitialData response:", dataResponse);
        this.filtersData.selectOptions.schools = dataResponse;
        this._sweetalertService.swalClose();
      },
      error: (err) => {
        console.log("getAllInitialData error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo cargar la información correctamente, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      },
      complete: () => {
        console.log("getAllInitialData complete:");
      }
    });
  }

  protected onSchoolSelectChange(optionSelected: IDropdownItem): void {
    this.filtersData.dataSelected.showSchool = false;
    this.MANAGE_SELECTS_DATA.showUserData = false;
    console.log('optionSelected', optionSelected);
    this.filtersData.dataSelected.school = +optionSelected.value!;
    this.getAllUserJobsContractsFromSchoolID(+optionSelected.value!);
  }

  protected onProfesorSelectChange(optionSelected: IDropdownItem): void {
    this.MANAGE_SELECTS_DATA.showUserData = false;
    console.log('optionSelected', optionSelected);
    let selected: UserJobsContract = this.MANAGE_SELECTS_DATA.USER_JOB_CONTRACT.find( x => x.id == optionSelected.value)!;
    this.MANAGE_SELECTS_DATA.userIdSelected = selected.UserID!;
    this.getAllScheduleFromProfesorID(selected);
  }

  private getAllUserJobsContractsFromSchoolID(schoolID: number): void {
    this.MANAGE_SELECTS_DATA.USER_JOB_CONTRACT = [];
    console.log('getAllUserJobsContractsFromSchoolID: ', schoolID);
    const today = new Date();
    const nextWeek = new Date(today.setDate(today.getDate() + 5));
    const dataToGetAllUserContracts = {
      path: `/Schools/${schoolID}/UserJobsContracts`,
      filter: {
        include: [ "TypeContracts", "Userapps" ],
        where: {
          and: [
            { StartContract: { lt: nextWeek.toISOString() } },
            { EndContract: { gt: nextWeek.toISOString() } },
          ],
        },
      }
    };
    console.log('dataToGetAllUserContracts', dataToGetAllUserContracts);
    
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin({
      FJ_ALL_USER_CONTRACT_LIST: this._apiService.get<UserJobsContract>( dataToGetAllUserContracts ).pipe( take(1) ),
    }).subscribe({
      next: (forkjoinResponse) => {
        console.log("getAllUserJobsContractsFromSchoolID response:", forkjoinResponse);
        let { FJ_ALL_USER_CONTRACT_LIST } = forkjoinResponse;
        this.MANAGE_SELECTS_DATA.USER_JOB_CONTRACT = FJ_ALL_USER_CONTRACT_LIST;
        this.filtersData.dataSelected.showSchool = true;
        
        this._sweetalertService.swalClose();
      },
      error: (err) => {
        console.log("getAllUserJobsContractsFromSchoolID error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo cargar la información correctamente, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      },
      complete: () => {
        console.log("getAllUserJobsContractsFromSchoolID complete:");
      }
    });
  }

  private getAllScheduleFromProfesorID(userJobContract: UserJobsContract): void {
    console.log('getAllScheduleFromProfesorID: ', userJobContract);
    this.MANAGE_SELECTS_DATA.showUserData = true;

    /** INICIO LOGICA SEMANA ACTUAL */
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 (Domingo) a 6 (Sábado)
    // Obtener el pasado domingo
    const lastSunday = new Date(today);
    lastSunday.setDate(today.getDate() - dayOfWeek);
    // Obtener el próximo sábado
    const nextSaturday = new Date(today);
    nextSaturday.setDate(today.getDate() + (6 - dayOfWeek));
    console.log(`Pasado domingo: ${lastSunday.toDateString()}`);
    console.log(`Próximo sábado: ${nextSaturday.toDateString()}`);
    const currentYear = new Date().getFullYear();
    console.log(`El año actual es: ${currentYear}`);
    /** FIN LOGICA SEMANA ACTUAL */

    const dataToGetAllUserContracts = {
      path: `/ScheduleSubjects`,
      filter: {
        where: { and: [
          { UserID: userJobContract.UserID },
          // { "Day": {
          //   "between": [`${{currentYear}}-01-01T00:00:00.000Z`, `${{currentYear}}-12-31T23:59:59.999Z`]
          // } }
        ] },
        include: [{
          SubjectGroups: [
            "subjects",
            { coursesgroup: "courseOffer" }
          ]
        }]
      }
    };
    
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    this.showCalendar = false;
    this.events = [];
    forkJoin({
      FJ_ALL_USER_SCHEDULLE: this._apiService.get<ScheduleSubject>( dataToGetAllUserContracts ).pipe( take(1) ),
      FJ_WEEK_USER_SCHEDULLE: this._apiService.get<ScheduleSubject>( dataToGetAllUserContracts ).pipe( take(1) ),
    }).subscribe({
      next: (forkjoinResponse) => {
        console.log("getAllScheduleFromProfesorID response:", forkjoinResponse);
        let { FJ_ALL_USER_SCHEDULLE } = forkjoinResponse;
        FJ_ALL_USER_SCHEDULLE.forEach((element: ScheduleSubject) => {
          this.events.push({
            title: `
              ${element.SubjectGroups?.subjects?.NameSubject}<br>
              ${element.SubjectGroups?.coursesgroup?.courseOffer?.NameCourseOfer}<br>
              ${element.SubjectGroups?.NameSubjectGroup}
            `,
            color: {
              // primary: '#5d80a2',
              // secondary: '#84B3E1',
              primary: '#E0EAFF',
              secondary: '#1F3C7A',
            },
            start: moment(element.HourStart).toDate(),
            end: moment(element.HourEnd).toDate(),
            resizable: {
              beforeStart: false,
              afterEnd: false,
            },
            draggable: false,
            actions: [],
            // meta: {
            //   scheduleSubjectID: schedule.id,
            //   subjectGroupID: element.id,
            //   subject: element.subjects,
            //   courseGroup: subjectGroup.coursesgroup,
            //   user: element.userapp,
            // },
          })
        });
        this.showCalendar = true;
        this._sweetalertService.swalClose();
      },
      error: (err) => {
        console.log("getAllScheduleFromProfesorID error:", err);
        this._sweetalertService.swalError('Error', 'No se pudo cargar la información correctamente, intentelo nuevamente, si el error persiste, contacte a soporte', ()=>{});
      },
      complete: () => {
        console.log("getAllScheduleFromProfesorID complete:");
      }
    });
  }

  closeOpenMonthViewDay() {

  }


  viewDate: Date = new Date();
  view = CalendarView.Week;
  showCalendar: boolean = false;
  locale: string = 'es';
  events: CalendarEvent[] = [];

  handleEvent(event: CalendarEvent): void {
    console.log('Evento clickeado:', event);
  }

}

interface FiltersData {
  selectOptions: {
    schools: School[],
  };
  dataSelected: {
    school: number,
    showSchool: boolean,
  };
}

interface ManageSelectDataInt {
  USER_JOB_CONTRACT: UserJobsContract[],
  userIdSelected: number,
  showUserData: boolean,
}


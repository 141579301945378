export interface UserI {
  id: number;
  LastName1: string;
  LastName2: string;
  Name1: string;
  Name2: string;
  email: string;
  CedocEmail: string;
  Photo:string;
  UserDocuments?: UserDocumentsI[];
  roles?: RolesI[];
  UserCourses?: UserCoursesI[];
}

export interface UserDocumentsI {
  Document: string;
}

export interface RolesI {
  id: number;
  name: string;
}

export interface UserCoursesI {
  id: number;
  UserID: number;
  CourseGroupID: number;
  coursesOfers: CoursesOffersI;
  Absences: number;
  isWithdrawn:boolean;
}

export interface CoursesOffersI {
  id: number;
  CourseID: number;
  NameCourseOfer: string;
  school: SchoolI;
  RealSince: Date;
  Since: Date;
  Until: Date;
  RealUntil: Date;
  InscriptStart: Date;
  InscriptEnd: Date;
  course: CourseI;
  TypeCourseOFer?: {
    Category?: CategoryI
  }
}


export interface CategoryI{
  id:number;
  NameCategoryCourse:string;
  gpaCalculation:string;
  gradesComponentName:string;
}

export interface SchoolI {
  id: number;
  NameTSchool: string;
}

export interface CourseI {
  id: number;
  NameCourse: string;
  Subjects?: SubjectsI[];
}

export interface SubjectsI {
  id: number;
  NameSubject: string;
  SemesterNumber?: number;
  Creditos: number;
  userSubject?: UserSubjectI[];
}

export interface UserSubjectI {
  id: number,
  FinalRecord: number;
  Absences: number;
  IsAproved: boolean;
  IsHomolog: boolean;
  subjectGroup: SubjectGroupI;
  userRecord?: UserRecordI[];
}

export interface SubjectGroupI {
  CourseOferID: number;
  NameSubjectGroup: string;
  MidTerms: number;
  userapp?: {
    email: string,
    CedocEmail:string,
    Name1: string,
    Name2: string
    LastName1: string,
    LastName2: string
  },
  subjects: SubjectsI
}

export interface UserRecordI {
  id?:number,
  midTerm: MidTermI;
  IsModify: boolean;
  IsAccording:boolean;
  isModify:boolean;
  ParcialRecord: number;
  dateRecord: DateRecordI;
}

export interface MidTermI {
  NameMid: string;
  Porcent: number;
  MidTermNum: number;
}

export interface DateRecordI {
  DateEndRecord: Date;
  DateStartRecord:Date;
  attachmentLink: string;
}

export interface ParentI {
  id?: number;
  LastName1: string;
  LastName2?: string;
  Name1: string;
  Name2?: string;
  email: string;
  Document: string;
  photo?:string;
}

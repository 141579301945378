<div class="approval__item-container">
  <div class="approval__subject-title">
    <span class="approval-text approval-text--bold approval-text--title"
      (click)="showAdditionalData = true;"
      [ngStyle]="{'cursor': !showAdditionalData ? 'pointer' : 'text'}">
      {{ userSubject.SubjectV2GroupOffer.SubjectV2Offer.nameSubject }}
    </span>

    <span class="close-subject" *ngIf="showAdditionalData" (click)="showAdditionalData = false;">
      <i class="icon-i-close"></i>
    </span>
  </div>

  <div class="approval__item" [ngStyle]="setColumns()" [ngClass]="showAdditionalData ? 'approval__item--active' : ''">
    <div class="approval__list-subject">
      <span class="approval-text approval-text--blue">Créditos: {{ userSubject.SubjectV2GroupOffer.SubjectV2Offer.creditNumber || ''}}</span>
      <span class="approval-text">
        PROFESOR:
        {{ userSubject.SubjectV2GroupOffer.ProfessorToSubjectGroups?.[0]?.professor?.Name1 || 'n/a'}}
        {{ userSubject.SubjectV2GroupOffer.ProfessorToSubjectGroups?.[0]?.professor?.LastName1 || 'n/a'}}
      </span>
      <span class="approval-text approval-text--italic">e-mail:
        {{ userSubject.SubjectV2GroupOffer.ProfessorToSubjectGroups?.[0]?.professor?.email || 'n/a' }}
      </span>
      <span class="approval-text">
        GRUPO: {{ userSubject.SubjectV2GroupOffer.nameSubjectV2GroupOffer }}
      </span>
    </div>

    <!-- Absences col -->
    <span class="approval-text approval-text--center">{{ userSubject.totalAbsences || 0 }}</span>
    <!-- Final record col -->
    <span class="approval-text approval-text--center">{{ userSubject.finalRecord || '-'}}</span>
    <!-- Enable record col -->
    <span class="approval-text approval-text--center">-</span>
    <!-- Status col -->
    <div class="approval-status approval-text" *ngIf="showStatus">
      <span></span> Cursando
    </div>
  </div>

  <div class="list-subject__info" [hidden]="!showAdditionalData">
    <!-- MIDTERM -->
    <div class="list-subject__info-container">
      <div class="list-subject__header">
        <span class="approval-text">NOMBRE DE LA CATEGORÍA</span>
        <span class="approval-text approval-text--center">PORCENTAJE</span>
        <span class="approval-text approval-text--center">NOTAS DEL ESTUDIANTE</span>
        <span class="approval-text approval-text--center">DE ACUERDO</span>
      </div>

      <div class="list-subject__body-container">
        <ng-container *ngFor="let term of userSubject.UserRecordsV2">
          <div class="list-subject__row">
            <span class="approval-text approval-text--bold">{{ term.MidTermV2.nameMid }}</span>
            <span class="approval-text approval-text--bold approval-text--center">{{ term.MidTermV2.percentage }}%</span>
            <span class="approval-text approval-text--bold approval-text--center">{{ term.parcialRecord }}</span>
            <div class="note-actions">
              <ng-container *ngIf="isWithinDateRange">
                <ng-container *allowedRoles="[2,10]">
                  <button class="button button--approve" type="button" [ngClass]="term.isAccording ? 'disabled' : ''" (click)="setAccording(true, term)">Si</button>
                  <button class="button button--reject" type="button" [ngClass]="!term.isAccording ? 'disabled' : ''" (click)="setAccording(false, term)">No</button>
                </ng-container>
              </ng-container>
              <span class="approval-text approval-text--bold approval-text--center">{{ term.isAccording ? 'Si' : 'No' }}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

<!--     <button class="button button--delete" type="button"
      *allowedRoles="[1,6]"
      (click)="deleteAction.emit( { userSubjectInfo:userSubject, subjectInfo:userSubject.subjectGroup.subjects } )">
      Eliminar materia
    </button> -->
  </div>
</div>